import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
// import "../../component/css/agent_applications.css";

const RewardedAgent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setIsLoaded(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_rewarded_delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);

      setResult(resultM);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const data = [
    {
      name: "Nino Ola",
      phone: "0812843283209",
      reward: "cash",
      rewardDetails: {
        fullName: "Jude Ozoh",
        bankAccount: "2210908302",
        bank: "First Bank",
      },
      dateRewarded: "22/11/2024",
    },
    {
      name: "Nino Ola",
      phone: "0812843283209",
      reward: "cash",
      rewardDetails: {
        fullName: "Jude Ozoh",
        bankAccount: "2210908302",
        bank: "First Bank",
      },
      dateRewarded: "22/11/2024",
    },
    {
      name: "Nino Ola",
      phone: "0812843283209",
      reward: "cash",
      rewardDetails: {
        fullName: "Jude Ozoh",
        bankAccount: "2210908302",
        bank: "First Bank",
      },
      dateRewarded: "22/11/2024",
    },
  ];

  if (!isLoaded) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader color={"#1FAA08"} size={100} />
      </div>
    );
  } else if (result.msg === "Success") {
    return (
      <div>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Reward</th>
              <th>Agent Reward Details</th>
              <th>Date Rewarded</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.phone}</td>
                <td>{item.reward}</td>
                <td>
                  <div>Full Name: {item.rewardDetails.fullName}</div>
                  <div>Bank Account: {item.rewardDetails.bankAccount}</div>
                  <div>Bank: {item.rewardDetails.bank}</div>
                </td>
                <td>{item.dateRewarded}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  } else if (result.msg === "no rewarded users found") {
    return <p style={{ textAlign: "center" }}>No rewarded agents found</p>;
  } else if (
    result.msg === "Account has been blocked, please contact master admin"
  ) {
    return (
      <p style={{ textAlign: "center" }}>
        Account has been blocked, please contact master admin
      </p>
    );
  }
};

export default RewardedAgent;
