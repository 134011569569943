import instant from '../component/images/instant.png';
import scheduled from '../component/images/scheduled.png';

export default function ContentTrimer(content){
    if(content.length > 45) {
        const Mcontent = content.slice(0, 45) + '...';
    return Mcontent;
    } else {
        return content
    }
}

export function NameTrimer(content) {
    if(content.length > 15) {
        const Mcontent = content.slice(0, 15) + '...';
    return Mcontent;
    } else {
        return content
    }
}

export function RoleTrimer(content) {
    if(content.length > 25) {
        const Mcontent = content.slice(0, 20) + '...';
    return Mcontent;
    } else {
        return content
    }
}

export function HandleHover(content) {
    return content;
}

export function TypeDeterminer(content) {
    if(content === 'instant') {
        return instant;
    } else {
        return scheduled;
    }
}

export function ClassNameDeterminer(content) {
    return 'shadow-position';
}