import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../component/css/individual_fleet.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import aang from "../../component/images/aang.jpg";
import emptybox from "../../component/images/emptybox.png";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export const DataChecker = (data) => {
  //console.log(data)
  if (data === "nil") {
    return "No Data Yet";
  } else if (data) {
    return data;
  } else {
    return "No Data Yet";
  }
};

export default function Individual_Fleet() {
  const [data, getData] = useState([]);
  const [data2, getData2] = useState([]);
  const location = useLocation();
  const fleet_manager_id = location.state.id;
  //console.log(location);
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [result, setResult] = useState("");
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);
  let isUnderFleetManager = "";

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const addPagec = () => {
    if (data2?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
  };

  const fetchData = async (pageCountM) => {
    console.log({
      token: token,
      fleet_manager_id: fleet_manager_id,
    });
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/view_fleet_manager",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            fleet_manager_id: fleet_manager_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const result = await response.json();
      setIsLoaded(true);
      //console.log(result)
      getData(result.fleet_manager);
      //console.log(data);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
      } else {
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/view_delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            fleet_manager_id: fleet_manager_id,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      //console.log(resultM)
      getData2(resultM.delivery_agents);
      //console.log(data);

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten succesfully');
      } else {
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result.msg === "Success") {
    return (
      <div className="individual-fleet-screen">
        <div className="individual-fleet-details">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="fleet-details">
            <div className="fleet-profile">
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="fleet-image"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="fleet-about">
              <div className="agent-name-info">{data?.fullname}</div>
              <br></br>
              <span>Fleet Name: </span>
              {data?.fleet_name}
              <br />
              <span>Fleet ID: </span> {data?.fleet_manager_code}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Gender:</span> {data?.gender}
              <br></br>
              <span>State of Operation:</span> {data?.state}
              <br></br>
              <span>City of Operation:</span> {data?.city}
              <br></br>
              <span>Nin:</span> {data?.nin}
              <br></br>
              <span>Company Name:</span> {DataChecker(data?.company_name)}
              <br></br>
              <span>CAC reg no:</span> {DataChecker(data?.cac_reg_no)}
              <br></br>
              <span>Phone no: </span> {data?.phone_no}
              <br></br>
              <span>Fleet Total Earning: </span>
              {ZeroChecker(data?.total_earnings)}
              <br></br>
            </div>
            <div className="agent-about">
              <div className="agent-about-name">Bank Details</div>
              <br></br>
              <span>Bank Name :</span>{" "}
              {DataChecker(data?.bank_details?.bank_name)}
              <br></br>
              <span>Account Name:</span>{" "}
              {DataChecker(data?.bank_details?.account_name)}
              <br></br>
              <span>Account Type:</span>{" "}
              {DataChecker(data?.bank_details?.account_type)}
              <br></br>
              {/* <span>BVN:</span> {DataChecker(data?.bank_details?.bvn)}
              <br></br> */}
              <span>Account Number: </span>{" "}
              {DataChecker(data?.bank_details?.account_no)}
              <br></br>
            </div>
            <div className="fleet-vehicle">
              <div className="fleet-manager-name">Fleet Details</div>
              <br className="fleet-manager-name"></br>
              <span>Bikes:</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_bikes.toLocaleString()
              )}
              <br></br>
              <span>Cars :</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_cars.toLocaleString()
              )}
              <br></br>
              <span>Buses :</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_vans.toLocaleString()
              )}
              <br></br>
              <span>Trucks :</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_trucks.toLocaleString()
              )}
              <br></br>
              <span>Total No of Agents :</span>{" "}
              {DataChecker(data?.fleet_size.toLocaleString())}
              <br></br>
            </div>
          </div>

          <div className="result-per-page">
            <form>
              Results per page
              <input
                className="chizys-input"
                type="number"
                value={resultPerPage}
                onChange={(e) => setResultPerPage(e.target.value)}
              />
              <button className="chizys-button" onClick={handleButtonClick}>
                Done
              </button>
            </form>
          </div>
          <div className="fleet-table">
            <table>
              <thead>
                <th>Image</th>
                <th>Agent ID</th>
                <th>Full Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
              </thead>
              <tbody>
                {data2.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="users-table-pic">
                        <img
                          src={item?.img_url ? item?.img_url : aang}
                          alt=""
                          className="users-table-pic"
                        />
                      </div>{" "}
                    </td>
                    <td>{item?.delivery_agent_code}</td>
                    <td className={ClassNameDeterminer(item?.fullname)}>
                      {NameTrimer(DataChecker(item?.fullname))}
                      <p style={{ display: "none" }}>
                        {DataChecker(item?.fullname)}
                      </p>
                    </td>
                    <td>{item?.email}</td>
                    <td>{item?.phone_no}</td>
                    <td
                      onClick={() =>
                        navigate("/App/Individual_Delivery_Agent", {
                          state: {
                            id: item._id,
                            fleet_manager_code: item.fleet_manager_code,
                          },
                        })
                      }
                    >
                      View profile
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              height: "20px",
              display: "flex",
              alignContent: "center",
              justifyContent: "flex-end",
              paddingRight: "20px",
              marginTop: "10px",
            }}
          >
            <div>
              {disable ? (
                <ClipLoader color={"black"} loading={disable} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                  onClick={minusPagec}
                />
              )}
            </div>
            <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
            <div>
              {disable1 ? (
                <ClipLoader color={"black"} loading={disable1} size={15} />
              ) : (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={
                    data2?.length <= resultPerPage - 1
                      ? "icon-space-less"
                      : "icon-space"
                  }
                  onClick={addPagec}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (result.msg === "No delivery agent under this fleet manager") {
    return (
      <div className="individual-fleet-screen">
        <div className="individual-fleet-details">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="fleet-details">
            <div className="fleet-profile">
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="fleet-image"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="fleet-about1">
              <div className="agent-name-info">{data?.fullname}</div>
              <br></br>
              <span>Fleet Name: </span>
              {data?.fleet_name}
              <br />
              <span>Fleet ID: </span> {data?.fleet_manager_code}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Gender:</span> {data?.gender}
              <br></br>
              <span>State of Operation:</span> {data?.state}
              <br></br>
              <span>City of Operation:</span> {data?.city}
              <br></br>
              <span>Nin:</span> {data?.nin}
              <br></br>
              <span>Company Name:</span> {DataChecker(data?.company_name)}
              <br></br>
              <span>CAC reg no:</span> {DataChecker(data?.cac_reg_no)}
              <br></br>
              <span>Phone no: </span> {data?.phone_no}
              <br></br>
              <span>Fleet Total Earning: </span>
              {ZeroChecker(data?.total_earnings)}
              <br></br>
            </div>
            <div className="agent-about">
              <div className="agent-about-name">Bank Details</div>
              <br></br>
              <span>Bank Name :</span>{" "}
              {DataChecker(data?.bank_details?.bank_name)}
              <br></br>
              <span>Account Name:</span>{" "}
              {DataChecker(data?.bank_details?.account_name)}
              <br></br>
              <span>Account Type:</span>{" "}
              {DataChecker(data?.bank_details?.account_type)}
              <br></br>
              {/* <span>BVN:</span> {DataChecker(data?.bank_details?.bvn)}
              <br></br> */}
              <span>Account Number: </span>{" "}
              {DataChecker(data?.bank_details?.account_no)}
              <br></br>
            </div>
            <div className="fleet-vehicle">
              <div className="fleet-manager-name">Fleet Details</div>
              <br className="fleet-manager-name"></br>
              <span>Bikes:</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_bikes.toLocaleString()
              )}
              <br></br>
              <span>Cars :</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_cars.toLocaleString()
              )}
              <br></br>
              <span>Buses :</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_vans.toLocaleString()
              )}
              <br></br>
              <span>Trucks :</span>{" "}
              {DataChecker(
                data?.fleet_manager_vehicles?.no_of_trucks.toLocaleString()
              )}
              <br></br>
              <span>Total No of Agents :</span>{" "}
              {DataChecker(data?.fleet_size.toLocaleString())}
              <br></br>
            </div>
          </div>
          <div className="found-not">
            {/* <h2>Delivery Agents</h2> */}
            <img src={emptybox} alt="not found" />
            <p>No delivery agents under this fleet manager</p>
          </div>
        </div>
      </div>
    );
  }
}
