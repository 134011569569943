import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import emptybox from '../images/emptybox.png';
import '../css/no_report.css';
import User_Report from '../../pages/reports/User_Report';
import User_Resolved from '../../pages/reports/User_Resolved';
import User_Reporttoggle from '../../pages/reports/User_Reporttoggle';
import Agent_Reporttoggle from '../../pages/reports/Agent_Reporttoggle';

export default function No_Report() {
  const [toggle, setToggle] = useState(true);
  const [data, getData] = useState([]);
  const navigate = useNavigate();

  
  const firstClick = () => {
      setToggle(true);
  
      // navigate("/Pending-del");
  };
 
  const secondClick = () => {
    setToggle(false);
    // navigate("/Pending-del");
  };
  

    return (
        <div className='reports'>
          <div className='reports-properties'>
            <div className='reports-switch'>
              <div className='report-toggle'
                  onClick={firstClick} 
                  id={toggle ? "active" : "inactive2"}
            >  Users Reports</div>
            <div  
              className='report2-toggle' 
              onClick={secondClick}
              id={toggle ? "inactive" : "active2"}
            > Delivery Agents Reports</div>                
            </div>          
  
            {
              toggle === true  ? (
                <User_Reporttoggle
                  click={() => {
                    navigate("/App/User_Reporttoggle");
                  }}
                />
  
              ) : toggle === false  ? (
                <Agent_Reporttoggle
                  click2={() => {
                    navigate("/App/Agent_Reporttoggle"); 
                  }}
                />
              ) : null
            }
            
          </div>
            {/* <div className='align-not-found'>
                <div className='none11-found'>
                    <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
                </div>
                <div className='no-title11'>No Report found at the moment</div>
            </div> */}
        </div>
    )
}
 