import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

export default function Payment_record() {
  const navigate = useNavigate();
  const location = useLocation();

  // Redirect to Agent_Payment_Record if at Payment_Record root
  React.useEffect(() => {
    if (location.pathname === '/App/Payment_Record') {
      navigate('/App/Payment_Record/Agent_Payment_Record');
    }
  }, [location.pathname, navigate]);

  return (
    <div className="DA-payment-history">
      <div className="da-payment-props">
        <div className="da-payment-title">Payment History</div>
        <div className="payment-to">
          <div
            className="payment-toggle"
            onClick={() => navigate('/App/Payment_Record/Agent_Payment_Record')}
            id={location.pathname.endsWith('Agent_Payment_Record') ? 'active' : 'inactive2'}
          >
            Individual Delivery Agents
          </div>
          <div
            className="payment1-toggle"
            onClick={() => navigate('/App/Payment_Record/Fleet_Manager')}
            id={location.pathname.endsWith('Fleet_Manager') ? 'active2' : 'inactive'}
          >
            Fleet Managers
          </div>
        </div>

        {/* Render Nested Routes */}
        <Outlet />
      </div>
    </div>
  );
}
