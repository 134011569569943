import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword, deleteUser } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/popup.css";

export default function Delete_Delivery_Agent() {
  const navigate = useNavigate();
  const location = useLocation();
  const delivery_agent_id = location.state.details.id;
  const delivery_agent_name = location.state.details.name;
  const delivery_agent_code = location.state.details.code;
  const delivery_agent_email = location.state.details.email;
  const [data, getData] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  // console.log(`----------> ${JSON.stringify(location.state)}`);

  const handleDeleteDeliveryAgentFromMongoDB = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delete/delete_delivery_agent",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      // Parse the JSON response
      const result = await response.json();

      // Process data (if necessary)
      getData(result.delivery_agent);

      // If API call is successful, status code is 200
      // if (response.status === 200) {
      //   // Now sign in to Firebase and delete the user
      //   await handleFirebaseAccountDeletion();
      // } else {
      //   alert("Failed to delete delivery agent. Please try again.");
      // }
    } catch (error) {
      console.error("Error during the API request:", error);
      alert(
        "An error occurred while deleting the delivery agent. Please try again."
      );
    }
  };

  // Function to handle Firebase sign-in and account deletion
  const handleFirebaseAccountDeletion = async () => {
    const auth = getAuth();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        delivery_agent_email,
        "pickload_delivery_agent"
      );
      const user = userCredential.user;

      if (user?.uid) {
        // Delete the user in Firebase
        await deleteUser(user);
        console.log("Firebase account deleted successfully.");
        handleDeleteDeliveryAgentFromMongoDB();
      } else {
        alert("An error occurred. Could not find user in Firebase.");
      }
    } catch (error) {
      console.error(
        "Error signing in or deleting Firebase account:",
        error.message
      );
      alert("Error deleting Firebase account: " + error.message);
    }
  };

  // const handleDeleteDeliveryAgentFromMongoDB = async () => {
  //   try {
  //     const response = await fetch(
  //       "https://serverpickload.wl.r.appspot.com/admin_delete/delete_delivery_agent",
  //       {
  //         method: "POST",

  //         body: JSON.stringify({
  //           token: token,
  //           delivery_agent_id: delivery_agent_id,
  //         }),
  //         headers: {
  //           "Content-Type": "application/json",
  //           Accept: "application/json, text/plain, */*",
  //         },
  //       }
  //     );
  //     const result = await response.json();
  //     console.log(result);
  //     console.log(result.delivery_agent);
  //     getData(result.delivery_agent);
  //     //console.log('got here');
  //     //console.log(result);
  //     //console.log(data.vehicle_details, data.bank_details);

  //     if (response.status === 200) {
  //       //console.log('data gotten succesfully');
  //     }
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Delete agent Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to delete Delivery agent<br></br>{" "}
              {delivery_agent_name} with delivery agent ID<br></br>
              {delivery_agent_code}?
            </div>
          </div>
          <div className="popup-dialog-row">
            <div
              className="popup-dialog"
              onClick={() => navigate("/App/Delivery_Agents")}
            >
              No
            </div>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <div
              className="popup-dialog1"
              onClick={() => {
                handleFirebaseAccountDeletion();
                navigate("/App/Delivery_Agents");
              }}
            >
              Yes
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
