import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../component/css/individual_agent_payment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aang from "../../component/images/aang.jpg";
import No_Payment from "../../component/notfound/No_Payment";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};

export default function Individual_Agent_Payment() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [result, setResult] = useState();
  //console.log(location);
  const [pageCount, setPageCount] = useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const week = location.state.week;
  const month = location.state.month;
  const year = location.state.year;
  const delivery_agent_id = location.state.id;
  const status = location.state.status;
  const [resultPerPage, setResultPerPage] = useState(20);
  //console.log(location);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [isLoaded]);
  let DATE = {};

  const TimeConverter = (props) => {
    //console.log(props)
    const date = new Date(props.value);
    DATE = {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString(),
      combined: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
    };
    return DATE.date;
  };

  const DayConverter = (day) => {
    const date = new Date(day).getDay();
    let actual_day = "";
    //console.log(date);
    switch (date) {
      case 0:
        actual_day = "Sunday";
        break;
      case 1:
        actual_day = "Monday";
        break;
      case 2:
        actual_day = "Tuesday";
        break;
      case 3:
        actual_day = "Wednesday";
        break;
      case 4:
        actual_day = "Thursday";
        break;
      case 5:
        actual_day = "Friday";
        break;
      case 6:
        actual_day = "Saturday";
        break;
    }
    return actual_day;
  };

  const StatusDeterminer = () => {
    if (status === "paid") return "paid-logo";
    return "not-paid-logo";
  };

  const TextDeterminer = () => {
    if (status === "paid") return "PAID";
    return "NOT-PAID";
  };

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const addPagec = () => {
    if (data?.transactions.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_payment_record/agent",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
            week: week,
            month: month,
            year: year,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      setResult(resultM);
      setIsLoaded(true);
      //console.log(isLoaded);
      //console.log(resultM);
      setData(resultM);
      //console.log(resultM)
      //console.log(data);

      if (
        resultM.msg ===
        "delivery agents weekly transactions gotten successfully"
      ) {
        setDisable(false);
        setDisable1(false);
        //console.log('data gotten succesfully');
      } else {
        setData("");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (
    result.msg === "delivery agents weekly transactions gotten successfully"
  ) {
    return (
      <div className="individual-agent-payment">
        <div className="individual-agent-details">
          <div className="back1">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="week-no">
            Week {week}
            <div className={StatusDeterminer()}>{TextDeterminer()}</div>
          </div>
          <div className="agent-pay-details">
            <div className="agent-pay-profile">
              <img
                src={
                  data?.delivery_agent?.img_url
                    ? data?.delivery_agent?.img_url
                    : aang
                }
                alt="user"
                className="agent-pay-image"
              />
            </div>
            <div className="agent-pay-about">
              <span>{data?.delivery_agent?.fullname}</span>
              <br className="paymentsss-br"></br>
              <span>Agent ID:</span> {data?.delivery_agent?.delivery_agent_code}
              <br></br>
              <span>Vehicle type:</span>{" "}
              {data?.delivery_agent?.vehicle_details?.type}
              <br></br>
              <span>Vehicle color:</span>{" "}
              {data?.delivery_agent?.vehicle_details?.color}
              <br></br>
              <span>Plate number:</span>{" "}
              {data?.delivery_agent?.vehicle_details?.plate_no}
              <br></br>
              <span>Phone number:</span> {data?.delivery_agent?.phone_no}
              <br></br>
              <span>Week earnings:</span>{" "}
              {ZeroChecker(data?.total_weeekly_earnings)}
              <br></br>
            </div>
            <div className="agent-payment">
              <span>ACCOUNT DETAILS</span>
              <br />
              <br></br>
              <span>Account name:</span>{" "}
              {data?.delivery_agent?.bank_details?.account_name}
              <br></br>
              <span>Account number:</span>{" "}
              {data?.delivery_agent?.bank_details?.account_no}
              <br></br>
              <span>Account type :</span>{" "}
              {data?.delivery_agent?.bank_details?.account_type}
              <br></br>
              <span>Bank name:</span>{" "}
              {data?.delivery_agent?.bank_details?.bank_name}
              <br></br>
            </div>
          </div>
          <div className="fleet-table">
            <table>
              <thead>
                <th>Date</th>
                <th>Weekday</th>
                <th>Daily earnings</th>
              </thead>
              <tbody>
                {data?.transactions?.map((item, i) => (
                  <tr key={i} className="payment-data">
                    <td>{<TimeConverter value={item.timestamp} />}</td>
                    <td>{DayConverter(item.timestamp)}</td>
                    <td>{ZeroChecker(item?.amt_for_delivery_agent)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div style={{height:"20px", display:'flex', alignContent: "center", justifyContent: "flex-end", paddingRight: "20px", marginTop: "10px"}}>
                <div>
                  {                
                    disable ? <ClipLoader color={"black"} loading={disable}  size={15} /> : <FontAwesomeIcon icon={faAngleLeft} className={pageCount <= 1 ? "icon-space-less" : "icon-space"} onClick={minusPagec} />
                  }
                </div>
                <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
                <div>
                  {
                    disable1 ? <ClipLoader color={"black"} loading={disable1}  size={15} /> : <FontAwesomeIcon icon={faAngleRight} className={data?.transactions.length <= (resultPerPage -1) ? "icon-space-less" : "icon-space"} onClick={addPagec} /> 
                  }
                </div>
              </div> */}
        </div>
      </div>
    );
  } else if (result.msg === "No earnings made for this period") {
    //console.log(isLoaded);
    //console.log('data gotten successfully')
    return <No_Payment />;
  }
}
