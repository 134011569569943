import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom';
import emptybox from '../images/emptybox.png';
import '../css/no_report.css';
import Agent_Report from '../../pages/reports/Agent_Report';
import Agent_Resolved from '../../pages/reports/Agent_Resolved';

export default function No_AgentReport() {
    const [toggle, setToggle] = useState(true);
    const [data, getData] = useState([]);
    const navigate = useNavigate();

    
    const firstClick = () => {
        setToggle(true);
    
        // navigate("/Pending-del");
    };
   
    const secondClick = () => {
      setToggle(false);
      // navigate("/Pending-del");
    };
    

  return (
        <div className='align-not-found'>
            <div className='none11-found'>
                <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
            </div>
            <div className='no-title11'>No Report found at the moment</div>
        </div>
        

  )
}
