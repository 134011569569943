import React from 'react'
import '../../component/css/Head.css';
import pickloadlogo from '../../component/images/pickloadlogo.png';

export default function Login_Header() {
  return (
    <div className='login-topbar'>
      <div className='logintopbarWrappers'>
        <div className='login-topleft'>
          <div className='login-logo'>
            <img src={pickloadlogo} height="27px" width="120px" alt='PickLoad' className='login-pickloadlogo' /> </div>
          <div className='project-title'>  </div>                
        </div>
      </div>
    </div>
  )
}
