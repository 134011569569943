import React, { useState, useContext, useEffect } from "react";
import pickloadonlylogo from "../images/pickloadonlylogo.png";
// import pickloadlogo from "../images/pickloadlogo.png";
import pickloadlogo from "../images/pickloadlogo2.png";
import "../css/topbar.css";
import { useNavigate } from "react-router-dom";
import { TokenContext } from "../../component/token_context";
import SessionExpired from "../../pages/SessionExpired";

export default function Topbar() {
  const [data, getData] = useState([]);
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const value = useContext(TokenContext);
  const { fire, setFire, AdminRole, setAdminRole } = value;
  const [admin_role, setAdminRole1] = useState(null);

  // useEffect(() => {
  //   navigate('/App/Session_Expired');
  // }, [token === null, token === undefined]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://serverpickload.wl.r.appspot.com/admin_profile/view_profile",
          {
            method: "POST",

            body: JSON.stringify({
              token: token,
            }),
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json, text/plain, */*",
            },
          }
        );

        // getData(await response.json());
        const result = await response.json();
        getData(result.admin);
        //console.log(result.admin.role[0]);
        if (result.admin.role[0] === "master") {
          setAdminRole(result.admin.role);
          setAdminRole1("Master Admin");
        } else {
          setAdminRole1(result.admin.role[0]);
          setAdminRole(result.admin.role);
        }
        //console.log(result);
        //console.log(data);
        sessionStorage.setItem("admin_id", JSON.stringify(result?.admin?._id));

        if (response.status === 200) {
          //console.log('data gotten succesfully');
          setFire(false);
        } else {
          //console.log('some error occurred');
        }
      } catch (error) {
        //console.log(error)
      }
    };
    fetchData();
  }, [fire === true]);

  const handleClick = () => {
    // sessionStorage.clear();
    navigate("/");
  };

  if (!token) {
    return (
      <div className="logout">
        <div className="logout-container">
          <div className="logout-background">
            <div className="logout-confirmation">
              <div className="logout-confirmation-info">
                <br></br>
                Session Timeout<br></br>
                <br></br>
                {/* <img src={amico} width='110px' height='110px' alt='icon' /><br></br> */}
                <div className="logout-rematched">
                  Session timed out.
                  <br />
                  Do you want to login?
                </div>
              </div>
              <div className="logout-dialog-row">
                {/* <button className='logout-dialog' onClick={() => navigate('/App/Dashboard')}>No</button> */}
                <button className="logout-dialog1" onClick={handleClick}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="topbar">
        <div className="topbarWrappers">
          <div className="topleft">
            <div className="logo">
              <img
                src={pickloadlogo}
                height="27px"
                width="120px"
                alt="PickLoad"
                className="pickloadlogo"
              />{" "}
            </div>
            <div className="project-title"> </div>
          </div>

          <div
            className="topRight"
            onClick={() =>
              navigate("/App/Profile", { state: { details: data } })
            }
          >
            <img
              src={data?.img ? data?.img : pickloadonlylogo}
              width="30px"
              height="28px"
              alt=""
              className="topbar-image"
            />
            <div className="AdminField">
              <div className="AdminName">
                <div className="AdminName">{data.fullname}</div>
              </div>
              <div className="AdminRank">{admin_role}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
