import React, { useState } from "react";
import "../../component/css/change_number.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

export default function Change_Phone_Number() {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [phone_no, SetPhoneNo] = useState("");
  const [disable, setDisable] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setDisable(true);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_profile/edit_profile",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            phone_no: phone_no,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      if (response.status === 200) {
        //console.log('Phone number set successfully');
        setDisable(false);
        navigate("/App/Changed_Phone_Number_Successfully");
      } else {
        setDisable(false);
        //console.log('some error occurred');
      }
    } catch (e) {
      //console.log(e);
      setDisable(false);
    }
  };

  return (
    <div className="change-password">
      <div className="change-password-props">
        <div className="back2">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            onClick={() => navigate(-1)}
            className="back"
          ></FontAwesomeIcon>
        </div>
        <div className="change-password-style">Change Phone Number</div>
        <form className="name-form" onSubmit={handleSubmit}>
          <div className="existing-password">
            <div>
              <label className="new-no">New Phone Number</label>
              <div className="new-password-main">
                <input
                  type="text"
                  name="password"
                  className="new-phone-password"
                  placeholder="Enter New Phone Number"
                  value={phone_no}
                  onChange={(e) => SetPhoneNo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button className="save-changes1" disabled={disable}>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              "Save and Update"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
