import React from 'react'
import Login_Header from '../../pages/logout/Login_Header'
import '../css/otp.css';
import ellipse from '../images/ellipse.png'

export default function Otp() {
  return (
    <div className='otp'>
    <Login_Header />
        <div className='otp-props'>
                {/* <img src={ellipse} alt='' className='ellipse' /> */}
            <div className='otp-body'>
                <h1 className='otp-title'>Phone number verification</h1>
                <div className='otp-sms'>Enter the OTP sms sent by sms to 08056748212</div>
                <div className='otp-field'>
                    <input type='number' className='token-field-admin' maxLength={1} />
                    <input type='number' className='token-field-admin' maxLength={1} />
                    <input type='number' className='token-field-admin' maxLength={1} />
                    <input type='number' className='token-field-admin' maxLength={1} />
                </div>
                <div className='token-expiry'>
                    We would send you another one in <span className='token-timer'>00:45</span>
                </div>
                <button className='otp-done'>Done</button>
            </div>
        </div>
    </div>
  )
}






















// function App() {
//     const inputArr = [
//       {
//         type: "text",
//         id: 1,
//         value: ""
//       }
//     ];
  
//     const [arr, setArr] = useState(inputArr);
  
//     const addInput = () => {
//       setArr(s => {
//         return [
//           ...s,
//           {
//             type: "text",
//             value: ""
//           }
//         ];
//       });
//     };
  
//     const handleChange = e => {
//       e.preventDefault();
  
//       const index = e.target.id;
//       setArr(s => {
//         const newArr = s.slice();
//         newArr[index].value = e.target.value;
  
//         return newArr;
//       });
//     };
  
//     return (
//       <div>
//         <button onClick={addInput}>+</button>
//         {arr.map((item, i) => {
//           return (
//             <input
//               onChange={handleChange}
//               value={item.value}
//               id={i}
//               type={item.type}
//               size="40"
//             />
//           );
//         })}
//       </div>
//     );
//   }