import React from 'react'
import '../component/css/unauthorized.css';
import {useNavigate} from 'react-router-dom';

export default function Unauthorized() {
  //console.log('got here');
  const navigate = useNavigate();
  // const handleClick = () => {
  //   // sessionStorage.clear();
  //   navigate('/');
  // }

  return (
    <div className='parent-container'>
      <div className='main-container'>
        <h3>Unauthorized</h3><br></br><br></br>
        <p>Your account is Unauthorized to access this page</p>
      </div>
    </div>
  )
}