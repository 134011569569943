import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../css/blocked.css";
import aang from "../images/aang.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import No_Blocked_Agent from "../no_blocked/No_Blocked_Agent";
import { FaSearch } from "react-icons/fa";
import { TokenContext } from "../token_context";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import {
  ClassNameDeterminer,
  NameTrimer,
} from "../../component/content_trimer";

export default function Blocked_Agents() {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const [searchString, setSearchString] = useState("");
  const navigate = useNavigate();
  const values = useContext(TokenContext);
  const { token } = values;
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [search, setSearch] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      if (search) {
        fetchSearchData(pageCountM);
      } else {
        fetchData(pageCountM);
      }
    }
  };
  const addPagec = () => {
    if (data?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    if (search) {
      fetchSearchData(pageCountM);
    } else {
      fetchData(pageCountM);
    }
  };
  const handleButtonClick = (e) => {
    e.preventDefault();
    setPageCount(1);
    setIsLoaded(false);
  };
  const DataChecker = (data) => {
    if (data) {
      return data;
    } else {
      return "No Data Yet";
    }
  };

  const fetchData = async (pageCountM) => {
    try {
      setSearch(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/blocked_delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: JSON.parse(token),
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      //console.log(resultM)
      setResult(resultM);
      setIsLoaded(true);
      //console.log(resultM);
      //console.log(data);

      if (resultM.msg === "Success") {
        //console.log('data gotten succesfully');
        setData(resultM.blocked_delivery_agents);
        setDisable(false);
        setDisable1(false);
      } else if (resultM.msg === "No blocked delivery agent found") {
        //console.log('No blocked delivery agent found');
        setData("");
        //console.log('some error occurred');
      } else {
        setData("");
        //console.log('error');
      }
    } catch (error) {
      //console.log(error);
    }
  };
  const fetchSearchData = async (pageCountM) => {
    try {
      //console.log(searchString);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_search/blocked_delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: pageCountM || pageCount,
            search: searchString,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );

      //console.log('got here');
      // setData(await response.json());
      const result = await response.json();
      setResult(result);
      setData(result.delivery_agents);
      //console.log(result.delivery_agents);
      //console.log(data);

      if (result.msg === "Success") {
        //console.log('data gotten succesfully');
        setDisable(false);
        setDisable1(false);
      } else {
        setData("");
        //console.log('some error occurred');
      }
    } catch (error) {
      //console.log(error);
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (data?.length > 0) {
    //console.log('data gotten succesfully');
    return (
      <div className="blocked">
        <div className="back1">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            onClick={() => navigate("/App/Delivery_Agents")}
            className="back"
          ></FontAwesomeIcon>
        </div>
        <div className="blocked-properties">
          <h1 className="blocked-title">Blocked Delivery Agents</h1>
          <div className="blocked-header">
            <div className="blocked-search-box-container">
              <input
                type="text"
                placeholder="Search Delivery Agents"
                className="blocked-search-box"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <button className="blocked-searching">
                <FaSearch
                  onClick={() => {
                    fetchSearchData();
                    setSearch(true);
                    setPageCount(1);
                  }}
                />
              </button>
            </div>
          </div>

          <div className="result-per-page">
            <form>
              Results per page
              <input
                className="chizys-input"
                type="number"
                value={resultPerPage}
                onChange={(e) => setResultPerPage(e.target.value)}
              />
              <button className="chizys-button" onClick={handleButtonClick}>
                Done
              </button>
            </form>
          </div>
          <table className="agent-table">
            <th>
              <div className="agent-table-left">Picture</div>
            </th>
            <th>Agents ID</th>
            <th>Full name</th>
            <th>Email address</th>
            <th>Phone number</th>
            <tbody>
              {data?.map((item, i) => (
                <tr key={i}>
                  <td>
                    <div className="users-table-pic">
                      <img
                        src={item?.img_url ? item?.img_url : aang}
                        alt=""
                        className="users-table-pic"
                      />
                    </div>{" "}
                  </td>
                  <td>{item?.delivery_agent_code}</td>
                  <td className={ClassNameDeterminer(item?.fullname)}>
                    {NameTrimer(DataChecker(item?.fullname))}
                    <p style={{ display: "none" }}>
                      {DataChecker(item?.fullname)}
                    </p>
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.phone_no}</td>
                  <td>
                    <button
                      className="unblock"
                      onClick={() =>
                        navigate("/App/Unblock_Delivery_Agent", {
                          state: { details: item },
                        })
                      }
                    >
                      Unblock
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          style={{
            height: "20px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
            marginTop: "10px",
          }}
        >
          <div>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                onClick={minusPagec}
              />
            )}
          </div>
          <h6>{PageCounter(result.count, pageCount, resultPerPage)}</h6>
          <div>
            {disable1 ? (
              <ClipLoader color={"black"} loading={disable1} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className={
                  data?.length <= resultPerPage - 1
                    ? "icon-space-less"
                    : "icon-space"
                }
                onClick={addPagec}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (data?.length === 0) {
    //console.log('and here');
    return <No_Blocked_Agent />;
  }
}
