import { Outlet } from "react-router-dom";
import "./App.css";
import Topbar from "./component/topbar/Topbar";
import Sidebar from "./component/sidebar/Sidebar";
// import { Toaster } from "react-hot-toast"; // Import the Toaster component

// String httpBaseUrl = "serverpickload.wl.r.appspot.com";
// String dioBaseUrl = "https://serverpickload.wl.r.appspot.com";

function App() {
  return (
    <div>
      {/* <Toaster /> Add the Toaster component here */}
      <Topbar />
      <Sidebar />
      <Outlet />
    </div>
  );
}

export default App;
