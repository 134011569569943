import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/popup.css";

export default function Unblock_Fleet_Manager() {
  const navigate = useNavigate();
  const location = useLocation();
  //console.log(location);
  const fleet_manager_id = location.state.details._id;
  const delivery_agent_name = location.state.details.fleet_name;
  const delivery_agent_code = location.state.details.fleet_manager_code;
  const [data, getData] = useState([]);
  const [disable, setDisable] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const UnblockFleetManager = async () => {
    try {
      setDisable(true);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_block/unblock_fleet_manager",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            fleet_manager_id: fleet_manager_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      const result = await response.json();
      //console.log(result.fleet_manager);
      getData(result.fleet_manager);
      //console.log('got here');
      //console.log(result);
      //console.log(data.vehicle_details, data.bank_details);

      if (response.status === 200) {
        //console.log('data gotten succesfully');
        navigate("/App/Blocked_Fleet");
        setDisable(false);
      } else {
        //console.log('some error occurred');
        setDisable(false);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Unblock Fleet Manager Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to unblock this Fleet Manager<br></br>{" "}
              {delivery_agent_name} with Fleet ID<br></br>
              {delivery_agent_code}?
            </div>
          </div>
          <div className="popup-dialog-row">
            <button
              className="popup-dialog"
              disabled={disable}
              onClick={() => {
                setDisable(true);
                navigate("/App/Blocked_Fleet");
              }}
            >
              No
            </button>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <button
              className="popup-dialog1"
              disabled={disable}
              onClick={() => UnblockFleetManager()}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
