import React, { useState } from "react";
import "../css/popup.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function MaxTimeoutDelivery() {
  const navigate = useNavigate();
  const location = useLocation();
  const delivery_agent = location.state.details;
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const ConfirmAction = async () => {
    setButtonLoading(true);
    setErrorMessage(""); // Reset error message before API call

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_delivery/max_timeout_delivery",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent.id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await response.json();
      setButtonLoading(false);

      if (response.status === 200) {
        navigate(-1);
      } else {
        setErrorMessage(result?.msg || "An error occurred while processing your request.");
      }
    } catch (error) {
      console.error(error);
      setButtonLoading(false);
      setErrorMessage("Failed to connect to the server. Please try again.");
    }
  };

  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Confirm Timeout Delivery <br />
            </div>
            <div className="popup-rematched">
              Are you sure you want to max timeout for this Delivery Agent?
            </div>
          </div>

          {errorMessage && (
            <div className="popup-error">
              {errorMessage}
            </div>
          )}

          <div className="popup-dialog-row">
            <div className="popup-dialog" onClick={() => navigate(-1)}>
              No
            </div>
            <div className="middle-line">
              <hr className="popup-hr"></hr>
            </div>
            <div
              className={`popup-dialog1 ${buttonLoading ? "disabled" : ""}`}
              onClick={!buttonLoading ? ConfirmAction : null}
            >
              {buttonLoading ? "..." : "Yes"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
