import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/popup.css";
import { TokenContext } from "../token_context";
import ClipLoader from "react-spinners/ClipLoader";

export default function AssignAgent() {
  const navigate = useNavigate();
  const location = useLocation();

  // Extracting state passed via location
  const deliveryAgentDetails = location.state?.delivery_agent_details || {};
  const deliveryDetails = location.state?.delivery_details || {};

  // State variables
  const [data, setData] = useState([]);
  const [disable, setDisable] = useState(false);

  // Context and session variables
  const { token: contextToken } = useContext(TokenContext);
  const token = JSON.parse(sessionStorage.getItem("userToken")) || contextToken;
  const adminId = JSON.parse(sessionStorage.getItem("admin_id"));

  const handleClick = () => {
    if (deliveryDetails._id === "3") {
      fetchData2();
    } else if (deliveryDetails.delivery_status_is_cancelled_by === "delivery agent") {
      fetchData2();
    } else {
      fetchData();
    }
  };

  // console.log("Location State:", location.state);


  // API calls
  const fetchData2 = async () => {


    await assignDelivery(
      "https://serverpickload.wl.r.appspot.com/delivery_agent_delivery/assign_cancelled_delivery"
    );
  };

  const fetchData = async () => {
    await assignDelivery(
      "https://serverpickload.wl.r.appspot.com/admin_delivery/assign_delivery"
    );
  };


  const assignDelivery = async (url) => {
    setDisable(true);
  
    // Correctly logging data
    // console.log("Token:", token);
    // console.log("Delivery Details:", deliveryDetails);
    // console.log("Delivery Agent Details:", deliveryAgentDetails);
    // console.log("Delivery Agent Details:", deliveryAgentDetails);
  
    const payload = {
      token,
      delivery_id: deliveryDetails.delivery_id || deliveryDetails._id,
      // delivery_agent_img_id: deliveryDetails.delivery_agent_img_id,
      delivery_agent_id: deliveryAgentDetails._id,
    };
  
    try {
      console.log("Sending payload:", payload);
  
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json, text/plain, */*",
        },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
      // console.log("API Response:", result);
  
      if (response.ok) {
        setData(result.delivery_agent || []);
        window.alert("Delivery assigned successfully");
        setTimeout(() => navigate(-1), 1000);
      } else {
        throw new Error(result.message || "Failed to assign delivery");
      }
    } catch (error) {
      console.error("Error:", error.message);
      window.alert("Delivery not assigned. Please try again.");
    } finally {
      setDisable(false);
    }
  };
  
  return (
    <div className="popup">
      <div className="popup-container">
        <div className="popup-background">
          <div className="popup-confirmation">
            <div className="popup-confirmation-info">
              Assign Delivery Confirmation
            </div>
            <div className="popup-rematched">
              Are you sure you want to assign this Delivery to Delivery Agent
              with ID <strong>{deliveryAgentDetails.delivery_agent_code}</strong>?
            </div>
          </div>
          <div className="chizzys-popup-container">
            <button
              className="chizzys-popup-buttons"
              onClick={() => navigate(-1)}
              disabled={disable}
            >
              No
            </button>
            <button
              className="chizzys-popup-buttons1"
              onClick={handleClick}
              type="submit"
              disabled={disable}
            >
              {disable ? (
                <ClipLoader color="black" loading={disable} size={15} />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
