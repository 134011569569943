import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import emptybox from "../../component/images/emptybox.png";
import { FaSearch } from "react-icons/fa";
import "./individualreferral.css";
import back from "../../component/images/backdown.png";
import avatar from "../../component/images/avatar.png";
import { useNavigate, useLocation } from "react-router-dom";

const IndividualUserReferral = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const referral_code = location.state.referral_code;
  const username = location.state.name;
  const user_image = location.state.img;

  // const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState("");
  const [data, setData] = useState([]);
  const [page_state, setPageState] = useState(0);

  // const [data, setData] = useState([]);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const fetchData = async () => {
    try {
      setPageState(0);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_user_referrals",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            referral_code: referral_code,
            // referral_code: "T7711968",
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      //console.log(data);
      setResult(resultM);
      // setIsLoaded(true);

      if (resultM.msg === "Success") {
        setPageState(1);
      } else if (resultM.msg === "No rewarded users found") {
        setPageState(2);
      } else if (
        resultM.msg === "Account has been blocked, please contact master admin"
      ) {
        setPageState(3);
      } else {
        setPageState(4);
      }
    } catch (error) {
      console.log(error);
      setPageState(4);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const targetPropsData = [
    {
      name: "John Jude",
      phone: "08120192031",
      deliveries: 2,
      dates: ["05/02/24", "04/03/24"],
      ids: ["0013", "0165"],
      amounts: ["N2,000", "N1,000"],
    },
    {
      name: "Chinso Nwike",
      phone: "08120192033",
      deliveries: 10,
      dates: ["07/02/24", "06/03/24"],
      ids: ["0015", "0167"],
      amounts: ["N4,000", "N3,500"],
    },
    {
      name: "David ilca",
      phone: "08120192034",
      deliveries: 8,
      dates: ["08/02/24", "07/03/24"],
      ids: ["0016", "0168"],
      amounts: ["N3,500", "N2,800"],
    },
    {
      name: "Losa Made",
      phone: "08120192035",
      deliveries: 12,
      dates: ["09/02/24", "08/03/24"],
      ids: ["0017", "0169"],
      amounts: ["N5,000", "N4,000"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
    {
      name: "Gestra Oladimeji",
      phone: "08120192036",
      deliveries: 6,
      dates: ["10/02/24", "09/03/24"],
      ids: ["0018", "0170"],
      amounts: ["N2,500", "N2,200"],
    },
  ];

  const notmettargetPropsData = [
    {
      name: "John Jude",
      phone: "08120192031",
      deliveries: 2,
      dates: ["05/02/24", "04/03/24"],
      ids: ["0013", "0165"],
      amounts: ["N2,000", "N1,000"],
    },
    {
      name: "Jane Doe",
      phone: "08120192032",
      deliveries: 5,
      dates: ["06/02/24", "05/03/24"],
      ids: ["0014", "0166"],
      amounts: ["N3,000", "N2,500"],
    },
  ];

  const handleBackClick = () => {
    navigate(`/App/referrals`);
  };

  // console.log(`------page_state----> ${page_state}`);

  if (page_state === 0) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader color={"#1FAA08"} size={100} />
      </div>
    );
  } else if (page_state === 1) {
    return (
      <div className="individual_referral">
        <div className="ref_ind_top">
          <div className="ref_ind_name">
            <img
              src={back}
              width="41px"
              height="auto"
              alt="back"
              className="ref-back"
              onClick={handleBackClick}
            />
            <img src={user_image} width="64px" height="64px" alt="icon" />
            <p>{username}'s Referral</p>
          </div>
          <div className="ind_qual">
            <p>
              Total Deliveries: <span>{result.total_deliveries}</span>{" "}
            </p>
            <p>
              Qualified referrals:{" "}
              <span>
                {result.targets_met.length}/
                {result.targets_met.length + result.targets_not_met.length}
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="target_main_ctn">
          <div className="target_ctn">
            <p>Met target ({result.targets_met.length})</p>
            <div className="target_props_grid">
              {result.targets_met.map((item, index) => (
                <div key={index} className="target_props">
                  <div className="target_props_ctn">
                    <p>Name: {item.fullname}</p>
                    <p>Phone No: {item.phone_no}</p>
                    <p>Total deliveries: {item.total_no_of_deliveries}</p>
                    <div className="ref_date_amt">
                      <div className="ref_date">
                        <h3>Date</h3>
                        {item.deliveries.map((date, idx) => (
                          <p key={idx}>{date}</p>
                        ))}
                      </div>
                      <div className="ref_amt">
                        <h3>ID/ Amount</h3>
                        {item.deliveries.map((item, idx) => (
                          <p key={idx}>
                            <span>{item._id}</span>/{item.delivery_cost_user}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="target_ctn">
            <p>Not Met target ({result.targets_met.length})</p>
            <div className="target_props_grid">
              {result.targets_not_met.map((item, index) => (
                <div key={index} className="target_props">
                  <div className="target_props_ctn">
                    <p>Name: {item.fullname}</p>
                    <p>Phone No: {item.phone_no}</p>
                    <p>Total deliveries: {item.total_no_of_deliveries}</p>
                    <div className="ref_date_amt">
                      <div className="ref_date">
                        <h3>Date</h3>
                        {item.deliveries.map((date, idx) => (
                          <p key={idx}>{date}</p>
                        ))}
                      </div>
                      <div className="ref_amt">
                        <h3>ID/ Amount</h3>
                        {item.deliveries.map((item, idx) => (
                          <p key={idx}>
                            <span>{item._id}</span>/{item.delivery_cost_user}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else if (page_state === 2) {
    <>
      <div className="align-not-found">
        <div className="none11-found">
          <img src={emptybox} alt="No Users Found" className="no1-found" />
        </div>
        <div className="no-title11">No Users found at the moment</div>
      </div>
    </>;
  } else if (page_state === 3) {
    <>
      <div className="align-not-found">
        <div className="no-title11">
          Your account has been blocked. Please contact master admin
        </div>
      </div>
    </>;
  } else {
    <>
      <div className="align-not-found">
        <div className="no-title11">
          An error occurred. Please try again later
        </div>
      </div>
    </>;
  }
};

export default IndividualUserReferral;
