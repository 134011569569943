import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./individualreferral.css";
import back from "../../component/images/backdown.png";
import calender from "../../component/images/calender.png";
import cancelled from "../../component/images/cancelled.png";
import earning from "../../component/images/earning.png";
import dayjs from "dayjs";
import weekRangeGetter, { getWeekNumber } from "../../component/weekRanger";
import { ClipLoader } from "react-spinners";
import aang from "../../component/images/aang.jpg";

const IndividualAgentReferral = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const delivery_agent = location.state.delivery_agent;
  //   console.log(`----------> ${JSON.stringify(delivery_agent)}`);

  const handleBackClick = () => {
    navigate(`/App/referrals`);
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);
  const token = JSON.parse(sessionStorage.getItem("userToken"));

  const [cname1, setCname1] = useState("week1");
  const [cname2, setCname2] = useState("week2");
  const [cname3, setCname3] = useState("week2");
  const [cname4, setCname4] = useState("week2");
  const [cname5, setCname5] = useState("week2");
  const [cname6, setCname6] = useState("week2");
  const [Dweek, setDWeek] = useState(1);
  const [day, setDay] = useState(Number.parseInt(dayjs().day()));
  const [month, setMonth] = useState(Number.parseInt(dayjs().month()));
  const [year, setYear] = useState(Number.parseInt(dayjs().year()));
  const [date, setDate] = useState(dayjs(Date.now()).format("YYYY-MM"));
  const [Cweek, setCweek] = useState(1);

  const [selectedPaymentType, setSelectedPaymentType] = useState("daily");

  const setPageOption = (option) => {
    setSelectedPaymentType(option);

    //doing all of this so that the date can reset to the current day when the option is changed
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");

    setYear(year);
    setMonth(month);
    setDay(day);

    setSelectedDate(getCurrentDate()); // Set the selected date to the current date for Day, Month & Year
    setDate(dayjs(Date.now()).format("YYYY-MM")); // Set the selected date to the current date for Month & Year
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [selectedDate, setSelectedDate] = useState(getCurrentDate()); // Default to current date

  const [noti_data, setNotiData] = useState("");
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    fetchData();
  }, [isLoaded === false, Cweek, date, selectedDate]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_single_delivery_agent_reward",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            week: Number.parseInt(Cweek),
            month: Number.parseInt(month),
            year: Number.parseInt(year),
            delivery_agent_id: delivery_agent._id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      setResult(resultM);
      setIsLoaded(true);

      if (response.status === 200) {
        // console.log("data gotten succesfully");
      }
    } catch (error) {
      //console.log(error);
    }
  };

  useEffect(() => {
    const weekNo = getWeekNumber(year, month, new Date().getDate());
    switch (weekNo) {
      case 1:
        handleClick("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[0]
        );
        break;
      case 2:
        handleClick1("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[1]
        );
        break;
      case 3:
        handleClick2("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[2]
        );
        break;
      case 4:
        handleClick3("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[3]
        );
        break;
      case 5:
        handleClick4("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[4]
        );
        break;
      case 6:
        handleClick6("auto");
        setNotiData(
          "Payment has been made from " + weekRangeGetter(month, year)[5]
        );
        break;
    }
  }, []);

  const handle_MY_Calender = (e) => {
    const newDate = dayjs(e.target.value).format("YYYY-MM");
    const yearM = Number.parseInt(newDate.slice(0, 4));
    const monthM = Number.parseInt(newDate.slice(5, 7)) - 1;
    setDate(newDate);
    setMonth(monthM);
    setYear(yearM);
    weekRangeGetter(monthM, yearM);
    // console.log(newDate, yearM, monthM);
  };
  const handle_DMY_Calender = (e) => {
    const dateValue = e.target.value; // Format: YYYY-MM-DD
    setSelectedDate(dateValue);

    // Extract day, month, and year
    if (dateValue) {
      const [year, month, day] = dateValue.split("-");

      setYear(year);
      setMonth(month);
      setDay(day);
      //   console.log(`Selected Date: Day: ${day}, Month: ${month}, Year: ${year}`);
    }
  };

  const handleClassName1 = () => {
    setCname1("week1");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[0]
    );
  };
  const handleClassName2 = () => {
    setCname1("week2");
    setCname2("week1");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[1]
    );
  };
  const handleClassName3 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week1");
    setCname4("week2");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[2]
    );
  };
  const handleClassName4 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week1");
    setCname5("week2");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[3]
    );
  };
  const handleClassName5 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week1");
    setCname6("week2");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[4]
    );
  };
  const handleClassName6 = () => {
    setCname1("week2");
    setCname2("week2");
    setCname3("week2");
    setCname4("week2");
    setCname5("week2");
    setCname6("week1");
    setNotiData(
      "Payment has been made from " + weekRangeGetter(month, year)[5]
    );
  };

  const handleClick = (e) => {
    // console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      setCweek(1);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(1);
      handleClassName1();
      setDWeek("");
      setCweek(1);
    }
  };

  const handleClick1 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      setCweek(2);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(2);
      setDWeek("");
      handleClassName2();
      setCweek(2);
    }
  };

  const handleClick2 = (e) => {
    //console.log(e);
    if (e === "auto") {
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      setCweek(3);
    } else {
      e.preventDefault();
      //console.log("baddest");
      setIsLoaded(false);
      setDWeek("");
      // setWeek(3);
      handleClassName3();
      setCweek(3);
    }
  };

  const handleClick3 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      setCweek(4);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(4);
      setDWeek("");
      handleClassName4();
      setCweek(4);
    }
  };

  const handleClick4 = (e) => {
    //console.log(e);
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      setCweek(5);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(5);
      setDWeek("");
      handleClassName5();
      setCweek(5);
    }
  };
  const handleClick6 = (e) => {
    if (e === "auto") {
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      setCweek(6);
    } else {
      e.preventDefault();
      setIsLoaded(false);
      // setWeek(6);
      setDWeek("");
      handleClassName6();
      setCweek(6);
    }
  };

  const dates = [
    "12/9/24",
    "12/10/24",
    "12/11/24",
    "12/12/24",
    "12/13/24",
    "12/14/24",
    "12/15/24",
  ];
  const dailytargets = [
    "10/10",
    "10/10",
    "10/10",
    "10/10",
    "7/10",
    "7/10",
    "7/10",
  ];
  const weeklytargets = "62/70";

  const getCellStyle = (value) => ({
    color: value === "10/10" ? "green" : "black",
    fontWeight: value === "10/10" ? "bold" : "normal",
  });

  const handleRowClick = (date) => {
    navigate(`/App/agent_daily_target/${date}`);
  };

  return (
    <div className="individual_referral">
      <img
        src={back}
        width="41px"
        height="auto"
        alt="back"
        className="ref-back"
        onClick={handleBackClick}
      />
      <div className="individual-agent-props">
        <div className="ind-agent-details">
          <div className="ind-agent-props">
            <div className="agent-profile">
              <img
                src={delivery_agent?.img_url ? delivery_agent?.img_url : aang}
                alt=""
                style={{ objectFit: "cover" }}
                className="agent-image"
              />
            </div>

            {/* <img
              src={delivery_agent?.img_url ? delivery_agent?.img_url : aang}
              style={{ width: "215px", height: "auto" }}
              alt="img"
            /> */}
            <div className="ind-agent-content">
              <h3>{delivery_agent.fullname}</h3>
              <p>
                Agent ID: <span>{delivery_agent.delivery_agent_code}</span>
              </p>
              <p>
                Vehichle type:{" "}
                <span>
                  {result?.delivery_agent?.vehicle_details?.type ?? "..."}
                </span>
              </p>
              <p>
                Vehicle color:{" "}
                <span>
                  {result?.delivery_agent?.vehicle_details?.color ?? "..."}
                </span>
              </p>
              <p>
                Plate number:{" "}
                <span>
                  {result?.delivery_agent?.vehicle_details?.plate_no ?? "..."}
                </span>
              </p>
              <p>
                Phone number: <span>{delivery_agent.phone_no}</span>
              </p>
              <p>
                Email: <span>{delivery_agent.email}</span>
              </p>
            </div>
            <div className="ind-agent-acts">
              <div className="ind-agent-earns">
                <img src={earning} width="50px" height="auto" alt="earning" />
                <div className="ind-agent-text">
                  <p>Total earnings</p>
                  <p>N {delivery_agent.total_earnings}</p>
                </div>
              </div>
              <div className="ind-agent-earns">
                <img src={calender} width="50px" height="auto" alt="calender" />
                <div className="ind-agent-text">
                  <p>Total No. of deliveries</p>
                  <p>
                    {result?.delivery_agent?.no_completed_deliveries ?? "..."}
                  </p>
                </div>
              </div>
              <div className="ind-agent-earns">
                <img
                  src={cancelled}
                  width="50px"
                  height="auto"
                  alt="cancelled"
                />
                <div className="ind-agent-text">
                  <p>No. of cancelled delliveries</p>
                  <p>
                    {result?.delivery_agent?.no_cancelled_deliveries ?? "..."}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="ind-agent-history">
            <h1>Delivery History</h1>
            <div className="payment-history">
              <div className="payment-calender-container">
                <input
                  type="month"
                  name="schedule"
                  min="2021-12-31"
                  max="2043-01-01"
                  className="payment-calender"
                  value={date}
                  onChange={handle_MY_Calender}
                />
              </div>
              <div className="payment-week">
                <div className="week1date">
                  <div className={cname1} onClick={handleClick}>
                    WEEK 1
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[0]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname2} onClick={handleClick1}>
                    WEEK 2
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[1]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname3} onClick={handleClick2}>
                    WEEK 3
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[2]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname4} onClick={handleClick3}>
                    WEEK 4
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[3]}
                  </p>
                </div>

                <div className="week1date">
                  <div className={cname5} onClick={handleClick4}>
                    WEEK 5
                  </div>
                  <p className="week-duration">
                    {weekRangeGetter(month, year)[4]}
                  </p>
                </div>
                {weekRangeGetter(month, year)[5] ? (
                  <div className="week1date">
                    <div className={cname6} onClick={handleClick6}>
                      WEEK 6
                    </div>
                    <p className="week-duration">
                      {weekRangeGetter(month, year)[5]}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th style={{ paddingLeft: "3rem" }}>Daily Targets</th>
                    <th
                      style={{
                        borderLeft: "0.4px solid rgba(204, 202, 202, 1)",
                        paddingLeft: "2rem",
                      }}
                    >
                      Weekly Targets
                    </th>
                  </tr>
                </thead>

                {!isLoaded ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingLeft: "20vw",
                    }}
                  >
                    <ClipLoader color={"#1FAA08"} size={100} />
                  </div>
                ) : (
                  <tbody>
                    {dates.map((date, index) => (
                      <tr key={index} onClick={() => handleRowClick(date)}>
                        <td>{date}</td>
                        <td
                          style={{
                            paddingLeft: "5rem",
                            ...getCellStyle(dailytargets[index]),
                          }}
                        >
                          {dailytargets[index]}
                        </td>
                        {index === 0 && (
                          <td
                            rowSpan={7}
                            style={{
                              verticalAlign: "middle",
                              borderLeft: "0.4px solid rgba(204, 202, 202, 1)",
                              textAlign: "center",
                              fontWeight: "bold",
                              color: "blue",
                            }}
                          >
                            {weeklytargets}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualAgentReferral;
