import React from 'react'
import noref from "../../component/images/noref.png";

const NoUserReward = () => {
  return (
    <div>
        <div className="content-container">
            <table>
                <th style={{  width: "20%"}}>Name</th>
                <th style={{  width: "20%"}}>Phone Number</th>
                <th style={{  width: "20%"}}>Reward notifier </th>
                <th style={{  width: "20%"}}> User Reward details</th>
                <th style={{  width: "20%"}}>Reward Status</th>
            </table>
            <div className='no-referal'>
                <img
                    src={noref}
                    width="128px"
                    height="128px"
                    alt='icon'
                />
                <p>No Rewarded users yet</p>
            </div>
        </div>
    </div>
  )
}

export default NoUserReward