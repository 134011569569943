import React, { useEffect, useState } from "react";
import "../../component/css/system_message.css";
import allpic from "../../component/images/allpic.png";
import agentspic from "../../component/images/agentspic.png";
import userspic from "../../component/images/userspic.png";
import ClipLoader from "react-spinners/ClipLoader";

export default function System_Message() {
  const [stats, getStats] = useState([]);
  const [data, getData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [call, setCall] = useState("all");
  const [disable, setDisable] = useState(false);
  const [check, setCheck] = useState(true);

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const fetchData = async () => {
    try {
      const Stats = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_stats/statistics",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            pageCount: 1,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "Application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const statsresult = await Stats.json();
      getStats(statsresult.statistics);
      setResult(statsresult);
      //console.log(statsresult);
      //console.log(data);
      setIsLoaded(true);
      if (Stats.status === 200) {
        //console.log('data gotten succesfully');
        setDisable(false);
      } else {
        //console.log('some error occurred');
        setDisable(false);
      }
    } catch (error) {
      setDisable(false);
      //console.log(error)
    }
  };

  const SendUsersMessage = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_system_message/users",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            message: message,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "Application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(data);
      setIsLoaded(true);
      if (response.status === 200) {
        //console.log('Message sent successfully');
        window.alert("Message sent to all users succesfully");
        setDisable(false);
        setMessage("");
      } else {
        //console.log('some error occurred');
        window.alert("Message not sent");
        setDisable(false);
      }
    } catch (error) {
      //console.log(error)
      setDisable(false);
    }
  };

  const SendDeliverysAgentMessage = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_system_message/delivery_agents",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            message: message,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "Application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(data);
      setIsLoaded(true);
      if (response.status === 200) {
        setDisable(false);
        setMessage("");
        //console.log('Message sent successfully');
        window.alert("Message sent to all Delivery Agents succesfully");
      } else {
        //console.log('some error occurred');
        window.alert("Message not sent");
        setDisable(false);
      }
    } catch (error) {
      //console.log(error)
      setDisable(false);
    }
  };

  const SendAllMessage = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_system_message/all",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            message: message,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "Application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      //console.log(data);
      setIsLoaded(true);
      if (response.status === 200) {
        //console.log('Message sent successfully');
        setMessage("");
        window.alert("Message sent to Delivery Agents and Users succesfully");
        setDisable(false);
      } else {
        //console.log('some error occurred');
        window.alert("Message not sent");
        setDisable(false);
      }
    } catch (error) {
      //console.log(error)
      setDisable(false);
    }
  };

  const handleClick = () => {
    //console.log(call, message);
    setDisable(true);
    if (call === "users") {
      SendUsersMessage();
    } else if (call === "delivery agents") {
      SendDeliverysAgentMessage();
    } else {
      SendAllMessage();
    }
  };

  if (!isLoaded) {
    //console.log('got here')
    return (
      <h1 className="loading-pages1">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else {
    return (
      <div className="system-message">
        <div className="system-message-properties">
          <div className="system-message-title">Message Recepient</div>
          <div className="send-message-btn">
            <div className="all-user-message-main">
              <div className="message-master">
                <div className="all-user-agent-message-props">
                  <div className="message-icon">
                    {" "}
                    <img src={allpic} alt=" " className="system-message-icon" />
                  </div>
                  <div className="system-text-up">
                    All Users and Delivery agents
                  </div>
                  <div>
                    <label
                      onClick={() => {
                        setCall("all");
                        setCheck(!check);
                      }}
                    >
                      {" "}
                      <input
                        type="radio"
                        name="system-message"
                        className="message-btn"
                        checked={check}
                      />
                    </label>
                  </div>
                </div>
                <div className="all-user-agent-no1">
                  {stats.no_of_users + stats.no_of_delivery_agents}
                </div>
              </div>
            </div>
            <div className="all-user-message-main">
              <div className="message-master1">
                <div className="all-user-agent-message-props1">
                  <div className="message-icon1">
                    {" "}
                    <img
                      src={userspic}
                      alt=" "
                      className="system-message-icon1"
                    />
                  </div>
                  All Users
                  <div className="message-radio-btn1">
                    <label
                      onClick={() => {
                        setCall("users");
                        setCheck(false);
                      }}
                    >
                      {" "}
                      <input
                        type="radio"
                        name="system-message"
                        className="message-btn1"
                      />
                    </label>
                  </div>
                </div>
                <div className="all-user-agent-no1">{stats.no_of_users}</div>
              </div>
            </div>
            <div className="all-user-message-main">
              <div className="message-master2">
                <div className="all-user-agent-message-props2">
                  <div className="message-icon2">
                    {" "}
                    <img
                      src={agentspic}
                      alt=" "
                      className="system-message-icon2"
                    />
                  </div>
                  <div className="system-text-up2">All Delivery Agents</div>
                  <div>
                    <label
                      onClick={() => {
                        setCall("delivery agents");
                        setCheck(false);
                      }}
                    >
                      {" "}
                      <input
                        type="radio"
                        name="system-message"
                        className="message-btn2"
                      />
                    </label>
                  </div>
                </div>
                <div className="all-user-agent-no2">
                  {stats.no_of_delivery_agents}
                </div>
              </div>
            </div>
          </div>
          <div className="all-message-area">
            <textarea
              className="all-message-text"
              placeholder="type your message here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            >
              {message}
            </textarea>
            <div>
              <button
                className="send-all-message"
                disabled={disable}
                onClick={handleClick}
              >
                {disable ? (
                  <ClipLoader color={"black"} loading={disable} size={15} />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// {stats.no_of_delivery_agents}
// {stats.no_of_users}
