import React, {useState} from 'react';
import RewardedAgent from './RewardedAgent';
import RewardedUsers from './RewardedUsers';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

const RewardedReferrals = () => {
    const [activeContent, setActiveContent] = useState('user');
    const handleButtonClick = (content) => setActiveContent(content);

  
    
  return (
    <div className='referral'>
        <div className='referal-main'>
            <div className='referal-top'>
                <div className='referal-title'>
                    <Link to="/App/referrals" className="referal-back">
                        <FontAwesomeIcon
                            icon={faArrowLeftLong}
                            className="back"
                        >
                        </FontAwesomeIcon>
                    </Link>
                    <h1>{activeContent === 'delivery agent' ? 'Rewarded Delivery Agents' : 'Rewarded Users'}</h1>
                </div>
                <div className='referal-user-agent'>
                    <div className="referal-btn-ctn">
                        {['user', 'delivery agent'].map((content) => (
                            <button
                                key={content}
                                onClick={() => handleButtonClick(content)}
                                style={{
                                    backgroundColor: activeContent === content ? 'rgba(31, 170, 8, 1)' : 'transparent',
                                    color: activeContent === content ? 'rgba(255, 255, 255, 1)' : 'rgba(31, 170, 8, 1)',
                                    padding: '0 2.3rem',
                                    cursor: 'pointer',
                                    marginRight: '1rem',
                                    borderRadius: "3px",
                                    transition: "all 0.3s ease",
                                }}
                            >
                                {content === 'user' ? 'User' : 'Delivery Agent'}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="content-container">
                {activeContent === 'user' && <RewardedUsers />}
                {activeContent === 'delivery agent' && <RewardedAgent />}
            </div>
        </div>
    </div>
  )
}

export default RewardedReferrals