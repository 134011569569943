import React, { useState, useContext, useEffect } from "react";
import "../../component/css/change_number.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { TokenContext } from "../../component/token_context";

export default function Change_Admin_Role() {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [disable, setDisable] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState([]);
  const role = [];
  const location = useLocation();
  const [check, setCheck] = useState(true);
  const admin_id = location.state.id;
  //console.log(admin_id);
  //   const value = useContext(TokenContext);
  //   const {setFire} = value;

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const handleChange = (roleM) => {
    let i = -1;
    const present = role.some((element) => {
      i++;
      return element === roleM;
    });
    if (present) {
      role.splice(i, 1);
    } else {
      role.push(roleM);
    }
    //console.log(role);
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/master_admin/single_admin",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            admin_id: admin_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      setIsLoaded(true);
      const resultM = await response.json();
      //console.log(resultM);
      setData(resultM.admin);
    } catch (error) {
      //console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    try {
      const roleM = role.join();
      //console.log(role);
      e.preventDefault();
      setDisable(true);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/master_admin/edit_admin",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            role: roleM,
            admin_id: admin_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      if (response.status === 200) {
        //console.log('Phone number set successfully');
        setDisable(false);
        // setFire(true);
        navigate("/App/Changed_Admin_Role_Successfully");
      } else {
        setDisable(false);
        //console.log('some error occurred');
      }
    } catch (e) {
      //console.log(e);
      setDisable(false);
    }
  };

  return (
    <div className="add-admin">
      <div className="add-admin-properties">
        <div className="back2">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            onClick={() => navigate(-1)}
            className="back"
          ></FontAwesomeIcon>
        </div>
        <div className="change-password-style">
          <h4>Change Administrative Role</h4>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <label>
            <input
              type="checkbox"
              value="Master admin"
              className="role-checkbox"
              onChange={() => handleChange("master")}
            />
            Master Admin
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Operation manager"
              className="role-checkbox"
              onChange={() => handleChange("Operation Manager")}
            />
            Operation Manager
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Agents support"
              className="role-checkbox"
              onChange={() => handleChange("Agents Support")}
            />
            Agents Support
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Finance manager"
              className="role-checkbox"
              onChange={() => handleChange("Finance Manager")}
            />
            Finance Manager
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Users customer care"
              className="role-checkbox"
              onChange={() => handleChange("Users Customer care")}
            />
            Users Customer care
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              value="Marketing and branding team"
              className="role-checkbox"
              onChange={() => handleChange("Marketing and branding team")}
            />
            Marketing and branding team
          </label>
          <button className="chizy-button" disabled={disable}>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              "Save and Update"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}
