import React,  {useContext, createContext, useState} from "react";
import DeliveryImage from '../../component/images/DeliveryImage.png';
import "../../component/css/WelcomeUser.css";
import { Link, useNavigate, Outlet } from "react-router-dom";
import { TokenContext  } from '../../component/token_context';
import Login_Header from "./Login_Header";
import ClipLoader from "react-spinners/ClipLoader";

export default function Login(props) {
  const navigate = useNavigate()
  const value = useContext(TokenContext);
  const {handleformsubmit, message, setMessage, loginData, setLoginData, token, disable, setDisable } = value
  //console.log( "this is the details from",message, loginData, token);
  //console.log(value);
  
  if (message === 'Logged In successfully') {
    navigate("/App/Dashboard")
    setTimeout(() => {
      setLoginData({username: loginData.username, password: ''});
    }, 3000);
  } else if(message === 'All fields must be filled') {
    setMessage(<p style={{color: 'red'}}>All fields must be filled</p>)
  } else if(message === 'some error occured'){
    setMessage(<p style={{color: 'red'}}>Incorrect username or password</p>);
  }


  const handleChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    setLoginData({ ...loginData, [name]: value });
  };

  
  return (
    <>
      <div className="login-page">
        <Login_Header />
      
        <div className="  md:mx-auto  flex gap-2 bg-white rounded-[20px] md:shadow-[10px_7.5px_31.5px_rgba(84,84,84,0.36),-10px_-7.5px_31.5px_rgba(84,84,84,0.25)] my-[5%]    px-10  md:w-[70%] w-full " id="welcome-main">
          <div id="DeliveryImage" className="hidden w-[50%] md:block">
            <p>
              Door to Door <span id="yellow">delivery</span>
              <br /> services for individuals
              <br /> and businesses.
            </p>
            <br />
            <br />
            <img src={DeliveryImage} alt="Deliver" />
          </div>

          <div id="" className=" md:w-[45%]  w-full  p-0 h-full md:h-510px md:p-[50px_40px_30px_50px]  text-red-600 ">
            <h2 id="welcome ">Welcome Back Admin</h2>
            <br />

            <form className='login-form w-full' onSubmit={handleformsubmit} >
              <div className='agent-login-input w-full'>
                <label className='login-label'>Username</label><br></br>
                <input 
                  type='text' 
                  value={loginData.username}
                  onChange={handleChange}
                  name='username' 
                  placeholder='Enter you Username' 
                  className='relative text-[15px] outline-none p-2 md:pt-[1.5vh] md:pb-[1.5vh] w-full md:w-[30vw] pl-[10px] border-[1.68px] border-solid border-[rgba(10,16,52,0.7)] mb-[2vh] '                 
                />
              </div>
              <div className='agent-password'>
                <label className='login-label'>Password</label><br></br>
                <input 
                  type='password' 
                  name='password' 
                  value={loginData.password}
                  onChange={handleChange}
                  className='relative text-[15px] outline-none p-2 md:pt-[1.5vh] md:pb-[1.5vh] w-full md:w-[30vw] pl-[10px] border-[1.68px] border-solid border-[rgba(10,16,52,0.7)] mb-[2vh]' 
                  placeholder='Enter your password'                
                />
              </div>
              <div className='login-submit'>
                <button className='login-btn' type='submit' disabled={disable}>
                 {disable ? <ClipLoader color={"black"} loading={disable}  size={15} /> : "login" } 
                </button>
                <div className='message'>{message ? <p> {message}</p> :null} </div>
              </div>
            </form>
            <div className="">
            {/* <img src={DeliveryImage} alt="Deliver" className="w-full"/> */}
            </div>
          </div>
          
        </div>        
      </div>
      <Outlet />
    </>
  )
}








































































// import React, {useContext, createContext, useState} from 'react';
// import '../../component/css/login.css';
// import App from '../../App';
// import Dashboard from '../dashboard/Dashboard';
// import { useNavigate, Outlet } from 'react-router-dom';
// import { TokenContext  } from '../../component/token_context';


// export default function Login(props) {
//   const navigate = useNavigate()
//   const value = useContext(TokenContext);
//   const {handleformsubmit, message, loginData, setLoginData, token } = value
//   //console.log( "this is the details from",message, loginData, token);
  
//   if (token) {
//     navigate("/App/Dashboard")
//   }

//   const handleChange = (e) => {
//     const target = e.target;
//     const { name, value } = target;
//     setLoginData({ ...loginData, [name]: value });
//   };
  

//   return (
//     // <TokenContext.Provider value={token}>
//       <div className='login'>
//       <div className='login-props'>
//         <div className='login-style'>
//           <h2 className='login-title'>WELCOME BACK</h2>
//           <form className='login-form' onSubmit={handleformsubmit} >
//             <div className='agent-login-input'>
//               <label className='login-label'>Username</label><br></br>
//               <input 
//                 type='text' 
//                 value={loginData.username}
//                 onChange={handleChange}
//                 name='username' 
//                 placeholder='Username' 
//                 className='login-input'                 
//               />
//             </div>
//             <div className='agent-password'>
//               <label className='login-label'>Password</label><br></br>
//               <input 
//                 type='password' 
//                 name='password' 
//                 value={loginData.password}
//                 onChange={handleChange}
//                 className='login-input' 
//                 placeholder='password'                
//               />
//             </div>
//             <div className='login-submit'>
//               <button className='login-btn' type='submit'>login</button>
//               <div className='message'>{message ? <p> {message}</p> :null} </div>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Outlet />
//       </div>
//       // {props.children}
//     // </TokenContext.Provider>
//   )
// }
