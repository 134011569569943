import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../../component/css/individual_delivery_agent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { ClipLoader } from "react-spinners";
import PageCounter from "../../component/page_counter";
import insdelivery from "../../component/images/insdelivery.png";
import cancelled from "../../component/images/cancelled.png";
import calender from "../../component/images/calender.png";
import totalearning from "../../component/images/totalearning.png";
import aang from "../../component/images/aang.jpg";
import vehicleimgavatar from "../../component/images/vehicleimgavatar.png";
import emptybox from "../../component/images/emptybox.png";
import axios from "axios";

export const DataChecker = (data) => {
  if (data) {
    return data;
  } else {
    return "No Data Yet";
  }
};

export const ZeroChecker = (cash) => {
  if (cash === 0) {
    return "₦0.00";
  } else {
    return "₦" + cash.toLocaleString();
  }
};
export default function Individual_Delivery_Agent() {
  const [data, getData] = useState([]);
  const [data2, getData2] = useState([]);
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [result2, setResult2] = useState("");
  const [result, setResult] = useState();
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  //console.log(location);
  const delivery_agent_id = location.state.id;
  const fleet_manager_code = location.state.fleet_manager_code;
  const [pageCount, setPageCount] = React.useState(1);
  const [disable, setDisable] = useState(false);
  const [disable1, setDisable1] = useState(false);
  const [disable3, setDisable3] = useState(false);
  const [resultPerPage, setResultPerPage] = useState(20);
  let isUnderFleetManager = "";
  if (fleet_manager_code) {
    isUnderFleetManager = true;
  } else {
    isUnderFleetManager = false;
  }
  //console.log(location);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [isLoaded === false]);

  const minusPagec = () => {
    if (pageCount <= 1) {
      return;
    } else {
      setDisable(true);
      const pageCountM = pageCount - 1;
      setPageCount((prev) => prev - 1);
      fetchData(pageCountM);
    }
  };
  const addPagec = () => {
    if (data2?.length <= resultPerPage - 1) return data;
    setDisable1(true);
    const pageCountM = pageCount + 1;
    setPageCount((prev) => prev + 1);
    fetchData(pageCountM);
  };

  const handlePaginateButtonClick = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    setPageCount(1);
    setDisable3(true);
  };

  const fetchData = async (pageCountM) => {
    // console.log(
    //   `----------> ${JSON.stringify({
    //     token: token,
    //     delivery_agent_id: delivery_agent_id,
    //   })}`
    // );
    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/view_ops/delivery_agent",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM = await response.json();
      setResult(resultM);

      setIsLoaded(true);

      getData(resultM.delivery_agent);
      setVehicleImgs(data?.vehicle_details?.img_urls);
      setDriverLicenseExpiryDate(
        data?.vehicle_details?.driver_license_expiry_date
      );

      console.log(`----------> ${JSON.stringify({ data })}`);
      //console.log(data);
      //console.log(isLoaded);
      //console.log(resultM)
    } catch (error) {
      //console.log(error);
    }

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_review/agent_reviews",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            delivery_agent_id: delivery_agent_id,
            pageCount: pageCountM || pageCount,
            resultPerPage: resultPerPage,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // getData(await response.json());
      const resultM1 = await response.json();
      setResult2(resultM1);
      //console.log(resultM1)
      getData2(resultM1.reviews);
      //console.log(data2);
      //console.log(result2);

      if (response.status === 200) {
        setDisable(false);
        setDisable1(false);
        setDisable3(false);
        //console.log('data gotten succesfully');
      }
      // if(data2.length === 0) {
      //   getData2(

      //   )
      // }
    } catch (error) {
      //console.log(error);
    }
  };

  // **** everything needed to edit vehicle images
  const [disableUpdateVehicleInfo, setUpdateVehicleInfo] = useState(false);
  const [driver_license_expiry_date, setDriverLicenseExpiryDate] = useState("");
  // Handler to update the expiry date
  const handleDateChange = (e) => {
    setDriverLicenseExpiryDate(e.target.value);
  };

  const [vehicleImgs, setVehicleImgs] = useState([]);
  const [newFiles, setNewFiles] = useState({}); // Store new files by index

  // This function is triggered when admin wants to add a new image
  const addNewVehicleImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      console.log(`----------> ${fileURL}`);

      // Add the new image to the end of the images array
      setVehicleImgs((prevImages) => [...prevImages, fileURL]);

      // Store the new file object in a separate state
      setNewFiles((prevFiles) => ({
        ...prevFiles,
        [vehicleImgs.length]: file, //The new file is added at the next available index in the newFiles state
      }));
    }
  };

  // using this so I can hide the default 'Choose file' button and then use useRef to link my custom button to the input element
  const fileInputRef = React.useRef(null);
  const handleButtonClick = () => {
    // Programmatically click the hidden file input
    fileInputRef.current.click();
  };

  // This function is triggered when a user selects a new image
  const chooseAnotherVehicleImage = (e, index) => {
    const file = e.target.files[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      console.log(`----------> ${fileURL}`);

      // Update the images to reflect the new image visually
      const updatedImages = [...vehicleImgs];
      updatedImages[index] = fileURL;
      setVehicleImgs(updatedImages);

      // Store the new file object in a separate state
      setNewFiles((prevFiles) => ({
        ...prevFiles,
        [index]: file, // Save the file at the correct index
      }));
    }
  };

  const removeImage = (index) => {
    // Remove the image at the specified index from vehicleImgs
    setVehicleImgs((prevImages) => prevImages.filter((_, i) => i !== index));

    // Remove the file at the specified index from newFiles
    setNewFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      delete updatedFiles[index]; // Remove the file at the index
      return updatedFiles;
    });
  };

  // Helper function to fetch a file from a URL
  const urlToFile = async (url, filename) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: blob.type });
  };

  const handleUpdatingVehicleImages = async () => {
    setUpdateVehicleInfo(true);
    const bodyFormData = new FormData();

    bodyFormData.append("token", token);
    bodyFormData.append("delivery_agent_id", delivery_agent_id);
    bodyFormData.append(
      "driver_license_expiry_date",
      driver_license_expiry_date
    );

    // Loop through the images and convert URLs to files if needed
    const filePromises = vehicleImgs.map((image, index) => {
      if (newFiles[index]) {
        // If there's a new file at this index, upload the file
        return newFiles[index];
      } else {
        // Otherwise, convert the URL to a file and upload it
        const filename = `image${index + 1}.jpg`; // You can modify this as needed
        return urlToFile(image, filename);
      }
    });

    // Wait for all files to be ready
    const files = await Promise.all(filePromises);

    // Append all files to the FormData
    files.forEach((file, index) => {
      bodyFormData.append("vehicle_details", file); // Use appropriate form field names
      // bodyFormData.append(`vehicle_details[]`, file); // Use appropriate form field names
    });

    // Perform the upload
    axios
      .post(
        "https://serverpickload.wl.r.appspot.com/admin_delivery_agent/edit_vehicle_details",
        bodyFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      .then((response) => {
        setUpdateVehicleInfo(false);

        console.log(`----------> ${response}`);
        if (response.data["msg"] == "success") {
          alert("Successfully updated");
          // showCustomToast(1, "Successful");
          //   toast.success("Sucess!");
        } else {
          //   showErrorToast(
          //     "oOps! Looks like something went wrong. Please try again"
          //   );
        }
      })
      .catch((error) => {
        console.log(`----------> ${error}`);
        // showErrorToast(
        //   "The server encountered an error. Please try again later"
        // );
        setUpdateVehicleInfo(false);
        alert(error);
      });
  };
  // **** -----------------------

  if (!isLoaded) {
    //console.log('got here');
    //console.log(isLoaded);
    return (
      <h1 className="loading-pages">
        <ClipLoader color={"#1FAA08"} size={100} />
      </h1>
    );
  } else if (result2.msg === "Success" && isUnderFleetManager === true) {
    //console.log(isLoaded);
    //console.log('data gotten succesfully');
    return (
      <div className="individual-delivery-agent-screen">
        <div className="delivery-agent-details">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="agent-details">
            <div className="agent-profile">
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                style={{ objectFit: "cover" }}
                className="agent-image"
              />
            </div>
            <div className="agent-about">
              <div className="agent-name-info">{data.fullname}</div>
              <br></br>
              <span>Agent ID:</span> {data?.delivery_agent_code}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Phone Number: </span> {data?.phone_no}
              <br></br>
              <span>Gender:</span> {data?.gender}
              <br></br>
              <span>Fleet Manager Code:</span> {data?.fleet_manager_code}
              <br />
              <span>Nin:</span> {data?.nin}
              <br></br>
              <span>Vehicle Type:</span> {data?.vehicle_details?.type}
              <br></br>
              <span>Vehicle Color:</span> {data?.vehicle_details?.color}
              <br></br>
              <span>Vehicle Name:</span> {data?.vehicle_details?.name}
              <br></br>
              <span>Plate Number:</span> {data?.vehicle_details?.plate_no}
              <br></br>
              <span>Address:</span> {data?.address}
              <br></br>
              <span>City of Operation:</span> {data?.city}
              <br></br>
              <span>State of Operation:</span> {data?.state}
              <br></br>
            </div>

            <div className="earning-board">
              <div className="agent-stats-board">
                <div className="agent-flboard">
                  <img src={totalearning} className="deliv-icons" alt="" />{" "}
                  Total earning's
                </div>{" "}
                <br></br>
                {ZeroChecker(data?.total_earnings)}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="agent-form-label">
              Vehicle & License Information
            </label>
            <div>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={(e) => addNewVehicleImage(e)}
              />

              {/* Custom button to trigger file selection */}
              <button
                className="add-more-images-button"
                onClick={handleButtonClick}
              >
                +
              </button>
            </div>
          </div>
          <div className="delivery-vehicle-type-medium2">
            <div className="vehicle-information">
              {vehicleImgs.map((url, index) => (
                <div className="vehicle-information-unit">
                  <img
                    key={index}
                    src={url ? url : vehicleimgavatar}
                    alt="icon"
                    className="vehicle-image"
                    // style={{objectFit: "cover"}}
                  />
                  <div className="vh-buttons-container">
                    <button
                      onClick={() => removeImage(index)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      X
                    </button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={(e) => chooseAnotherVehicleImage(e, index)}
                      className="hide-no-file-chosen"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="vehicle-images-parent">
            <div>
              <label className="agent-form-label">
                Driver's licence expiry date
              </label>

              {/* Display the selected date */}
              <div className="form-information">
                {driver_license_expiry_date
                  ? driver_license_expiry_date
                  : "Select drivers license expiry date"}
              </div>

              {/* Date picker */}
              <input
                type="date"
                value={driver_license_expiry_date}
                onChange={handleDateChange}
                className="date-picker-input"
              />
            </div>

            <button
              className="vh-update-button"
              disabled={disableUpdateVehicleInfo}
              onClick={() => handleUpdatingVehicleImages()}
            >
              {disableUpdateVehicleInfo ? (
                <ClipLoader
                  color={"black"}
                  loading={disableUpdateVehicleInfo}
                  size={15}
                />
              ) : (
                "Update"
              )}
            </button>
          </div>
          <br></br>
          <br></br>

          <div className="agent-history">
            <div className="agent-instant-request">
              <div className="agent-flash-display">
                <img src={insdelivery} className="deliv-icons" alt="" />
                <span className="flash-display">No of Instant Deliveries</span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_instant_deliveries}
              </div>
            </div>
            <div className="agent-scheduled-request">
              <div className="agent-flash-display">
                <img src={calender} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Scheduled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_scheduled_deliveries}
              </div>
            </div>
            <div className="agent-cancelled-request">
              <div className="agent-flash-display">
                <img src={cancelled} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Cancelled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_cancelled_deliveries}
              </div>
            </div>
          </div>
          <div className="result-per-page">
            <form>
              Results per page
              <input
                className="chizys-input"
                type="number"
                value={resultPerPage}
                onChange={(e) => setResultPerPage(e.target.value)}
              />
              <button
                className="chizys-button"
                disabled={disable3}
                onClick={handleButtonClick}
              >
                Done
              </button>
            </form>
          </div>
          <div className="agent-ratings">
            <div className="agent-ratings-title">Ratings & Reviews</div>
            {data2.map((item, i) => (
              <div className="agent-reviews">
                <div className="user-review" key={i}>
                  <img
                    src={item?.user_img ? item?.user_img : aang}
                    alt=""
                    className="user-image-review"
                  />
                  <div className="review-field">
                    <div className="review-name">{item?.user_name}</div>
                    <div className="review-text">{item?.review}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            height: "20px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
            marginTop: "10px",
          }}
        >
          <div>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                onClick={minusPagec}
              />
            )}
          </div>
          <h6>{PageCounter(result2.count, pageCount)}</h6>
          <div>
            {disable1 ? (
              <ClipLoader color={"black"} loading={disable1} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className={
                  data2?.length <= resultPerPage - 1
                    ? "icon-space-less"
                    : "icon-space"
                }
                onClick={addPagec}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (result2.msg === "Success" && isUnderFleetManager === false) {
    return (
      <div className="individual-delivery-agent-screen">
        <div className="delivery-agent-details">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="agent-details">
            <div className="agent-profile">
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="agent-image"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="agent-about">
              <div className="agent-name-info">{data.fullname}</div>
              <br></br>
              <span>Agent ID:</span> {data?.delivery_agent_code}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Phone Number: </span> {data?.phone_no}
              <br></br>
              <span>Gender:</span> {data?.gender}
              <br></br>
              <span>Nin:</span> {data?.nin}
              <br></br>
              <span>Vehicle Type:</span> {data?.vehicle_details?.type}
              <br></br>
              <span>Vehicle Color:</span> {data?.vehicle_details?.color}
              <br></br>
              <span>Vehicle Name:</span> {data?.vehicle_details?.name}
              <br></br>
              <span>Plate Number:</span> {data?.vehicle_details?.plate_no}
              <br></br>
              <span>Address:</span> {data?.address}
              <br></br>
              <span>City of Operation:</span> {data?.city}
              <br></br>
              <span>State of Operation:</span> {data?.state}
              <br></br>
              <div className="delivery-vehicle-type-medium2">
                <div className="delivery-vehicle-type-medium-name1">
                  Vehicle Images
                </div>
                <div className="delivery-vehicle-type-medium-name-vehicle1">
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[2]
                        ? data?.vehicle_details?.img_urls?.[2]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[3]
                        ? data?.vehicle_details?.img_urls?.[3]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            </div>

            <div className="agent-about">
              <div className="agent-about-name">Bank Details</div>
              <br></br>
              <span>Bank Name :</span>{" "}
              {DataChecker(data?.bank_details?.bank_name)}
              <br></br>
              <span>Account Name:</span>{" "}
              {DataChecker(data?.bank_details?.account_name)}
              <br></br>
              <span>Account Type:</span>{" "}
              {DataChecker(data?.bank_details?.account_type)}
              {/* <br></br>
              <span>BVN:</span> {DataChecker(data?.bank_details?.bvn)} */}
              <br></br>
              <span>Account Number: </span>{" "}
              {DataChecker(data?.bank_details?.account_no)}
              <br></br>
              {/* <div className="delivery-vehicle-type-medium2">
                <div className="delivery-vehicle-type-medium-name1">
                  Drivers License
                </div>
                <div className="delivery-vehicle-type-medium-name-vehicle1">
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[0]
                        ? data?.vehicle_details?.img_urls?.[0]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[1]
                        ? data?.vehicle_details?.img_urls?.[1]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div> */}
            </div>

            <div className="earning-board">
              <div className="agent-stats-board">
                <div className="agent-flboard">
                  <img src={totalearning} className="deliv-icons" alt="" />{" "}
                  Total earning's
                </div>{" "}
                <br></br>
                {ZeroChecker(data?.total_earnings)}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="agent-form-label">
              Vehicle & License Information
            </label>
            <div>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={(e) => addNewVehicleImage(e)}
              />

              {/* Custom button to trigger file selection */}
              <button
                className="add-more-images-button"
                onClick={handleButtonClick}
              >
                +
              </button>
            </div>
          </div>
          <div className="delivery-vehicle-type-medium2">
            <div className="vehicle-information">
              {vehicleImgs.map((url, index) => (
                <div className="vehicle-information-unit">
                  <img
                    key={index}
                    src={url ? url : vehicleimgavatar}
                    alt="icon"
                    className="vehicle-image"
                    // style={{objectFit: "cover"}}
                  />
                  <div className="vh-buttons-container">
                    <button
                      onClick={() => removeImage(index)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      X
                    </button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={(e) => chooseAnotherVehicleImage(e, index)}
                      className="hide-no-file-chosen"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="vehicle-images-parent">
            <div>
              <label className="agent-form-label">
                Driver's licence expiry date
              </label>

              {/* Display the selected date */}
              <div className="form-information">
                {driver_license_expiry_date
                  ? driver_license_expiry_date
                  : "Select drivers license expiry date"}
              </div>

              {/* Date picker */}
              <input
                type="date"
                value={driver_license_expiry_date}
                onChange={handleDateChange}
                className="date-picker-input"
              />
            </div>

            <button
              className="vh-update-button"
              disabled={disableUpdateVehicleInfo}
              onClick={() => handleUpdatingVehicleImages()}
            >
              {disableUpdateVehicleInfo ? (
                <ClipLoader
                  color={"black"}
                  loading={disableUpdateVehicleInfo}
                  size={15}
                />
              ) : (
                "Update"
              )}
            </button>
          </div>
          <br></br>
          <br></br>

          <div className="agent-history">
            <div className="agent-instant-request">
              <div className="agent-flash-display">
                <img src={insdelivery} className="deliv-icons" alt="" />
                <span className="flash-display">No of Instant Deliveries</span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_instant_deliveries}
              </div>
            </div>
            <div className="agent-scheduled-request">
              <div className="agent-flash-display">
                <img src={calender} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Scheduled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_scheduled_deliveries}
              </div>
            </div>
            <div className="agent-cancelled-request">
              <div className="agent-flash-display">
                <img src={cancelled} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Cancelled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_cancelled_deliveries}
              </div>
            </div>
          </div>
          <div className="result-per-page">
            <form>
              Results per page
              <input
                className="chizys-input"
                type="number"
                value={resultPerPage}
                onChange={(e) => setResultPerPage(e.target.value)}
              />
              <button
                className="chizys-button"
                disabled={disable3}
                onClick={handlePaginateButtonClick}
              >
                Done
              </button>
            </form>
          </div>
          <div className="agent-ratings">
            <div className="agent-ratings-title">Ratings & Reviews</div>
            {data2.map((item, i) => (
              <div className="agent-reviews">
                <div className="user-review" key={i}>
                  <img
                    src={item?.user_img ? item?.user_img : aang}
                    alt=""
                    className="user-image-review"
                  />
                  <div className="review-field">
                    <div className="review-name">{item?.user_name}</div>
                    <div className="review-text">{item?.review}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            height: "20px",
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
            marginTop: "10px",
          }}
        >
          <div>
            {disable ? (
              <ClipLoader color={"black"} loading={disable} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={pageCount <= 1 ? "icon-space-less" : "icon-space"}
                onClick={minusPagec}
              />
            )}
          </div>
          <h6>{PageCounter(result2.count, pageCount)}</h6>
          <div>
            {disable1 ? (
              <ClipLoader color={"black"} loading={disable1} size={15} />
            ) : (
              <FontAwesomeIcon
                icon={faAngleRight}
                className={
                  data2?.length <= resultPerPage - 1
                    ? "icon-space-less"
                    : "icon-space"
                }
                onClick={addPagec}
              />
            )}
          </div>
        </div>
      </div>
    );
  } else if (
    result2.msg === "No reviews available yet" &&
    isUnderFleetManager === true
  ) {
    //console.log(isLoaded);
    //console.log('data gotten succesfully');
    return (
      <div className="individual-delivery-agent-screen">
        <div className="delivery-agent-details">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="agent-details">
            <div className="agent-profile">
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="agent-image"
              />
            </div>
            <div className="agent-about">
              <div className="agent-name-info">{data.fullname}</div>
              <br></br>
              <span>Agent ID:</span> {data?.delivery_agent_code}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Phone Number: </span> {data?.phone_no}
              <br></br>
              <span>Gender:</span> {data?.gender}
              <br></br>
              <span>Nin:</span> {data?.nin}
              <br></br>
              <span>Vehicle Type:</span> {data?.vehicle_details?.type}
              <br></br>
              <span>Vehicle Color:</span> {data?.vehicle_details?.color}
              <br></br>
              <span>Vehicle Name:</span> {data?.vehicle_details?.name}
              <br></br>
              <span>Plate Number:</span> {data?.vehicle_details?.plate_no}
              <br></br>
              <span>Address:</span> {data?.address}
              <br></br>
              <span>City of Operation:</span> {data?.city}
              <br></br>
              <span>State of Operation:</span> {data?.state}
              <br></br>
              <br />
              {/* <div className="delivery-vehicle-type-medium2">
                <div className="delivery-vehicle-type-medium-name1">
                  Drivers License
                </div>
                <div className="delivery-vehicle-type-medium-name-vehicle1">
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[0]
                        ? data?.vehicle_details?.img_urls?.[0]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[1]
                        ? data?.vehicle_details?.img_urls?.[1]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                  />
                </div>
              </div> */}
            </div>

            <div className="earning-board">
              <div className="agent-stats-board">
                <div className="agent-flboard">
                  <img src={totalearning} className="deliv-icons" alt="" />{" "}
                  Total earning's
                </div>{" "}
                <br></br>
                {ZeroChecker(data?.total_earnings)}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="agent-form-label">
              Vehicle & License Information
            </label>
            <div>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={(e) => addNewVehicleImage(e)}
              />

              {/* Custom button to trigger file selection */}
              <button
                className="add-more-images-button"
                onClick={handleButtonClick}
              >
                +
              </button>
            </div>
          </div>
          <div className="delivery-vehicle-type-medium2">
            <div className="vehicle-information">
              {vehicleImgs.map((url, index) => (
                <div className="vehicle-information-unit">
                  <img
                    key={index}
                    src={url ? url : vehicleimgavatar}
                    alt="icon"
                    className="vehicle-image"
                    // style={{objectFit: "cover"}}
                  />
                  <div className="vh-buttons-container">
                    <button
                      onClick={() => removeImage(index)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      X
                    </button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={(e) => chooseAnotherVehicleImage(e, index)}
                      className="hide-no-file-chosen"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="vehicle-images-parent">
            <div>
              <label className="agent-form-label">
                Driver's licence expiry date
              </label>

              {/* Display the selected date */}
              <div className="form-information">
                {driver_license_expiry_date
                  ? driver_license_expiry_date
                  : "Select drivers license expiry date"}
              </div>

              {/* Date picker */}
              <input
                type="date"
                value={driver_license_expiry_date}
                onChange={handleDateChange}
                className="date-picker-input"
              />
            </div>

            <button
              className="vh-update-button"
              disabled={disableUpdateVehicleInfo}
              onClick={() => handleUpdatingVehicleImages()}
            >
              {disableUpdateVehicleInfo ? (
                <ClipLoader
                  color={"black"}
                  loading={disableUpdateVehicleInfo}
                  size={15}
                />
              ) : (
                "Update"
              )}
            </button>
          </div>
          <br></br>
          <br></br>

          <div className="agent-history">
            <div className="agent-instant-request">
              <div className="agent-flash-display">
                <img src={insdelivery} className="deliv-icons" alt="" />
                <span className="flash-display">No of Instant Deliveries</span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_instant_deliveries}
              </div>
            </div>
            <div className="agent-scheduled-request">
              <div className="agent-flash-display">
                <img src={calender} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Scheduled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_scheduled_deliveries}
              </div>
            </div>
            <div className="agent-cancelled-request">
              <div className="agent-flash-display">
                <img src={cancelled} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Cancelled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_cancelled_deliveries}
              </div>
            </div>
          </div>
          <div className="agent-ratings">
            <div className="found-not">
              <img src={emptybox} alt="not found" />
              <p>No ratings and reviews for this delivery agent</p>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (
    result2.msg === "No reviews available yet" &&
    isUnderFleetManager === false
  ) {
    return (
      <div className="individual-delivery-agent-screen">
        <div className="delivery-agent-details">
          <div className="back2">
            <FontAwesomeIcon
              icon={faArrowLeftLong}
              onClick={() => navigate(-1)}
              className="back"
            ></FontAwesomeIcon>
          </div>
          <div className="agent-details">
            <div className="agent-profile">
              <img
                src={data?.img_url ? data?.img_url : aang}
                alt=""
                className="agent-image"
                style={{ objectFit: "cover" }}
              />
            </div>
            <div className="agent-about">
              <div className="agent-name-info">{data.fullname}</div>
              <br></br>
              <span>Agent ID:</span> {data?.delivery_agent_code}
              <br></br>
              <span>E-mail:</span> {data?.email}
              <br></br>
              <span>Phone Number: </span> {data?.phone_no}
              <br></br>
              <span>Gender:</span> {data?.gender}
              <br></br>
              <span>Nin:</span> {data?.nin}
              <br></br>
              <span>Vehicle Type:</span> {data?.vehicle_details?.type}
              <br></br>
              <span>Vehicle Color:</span> {data?.vehicle_details?.color}
              <br></br>
              <span>Vehicle Name:</span> {data?.vehicle_details?.name}
              <br></br>
              <span>Plate Number:</span> {data?.vehicle_details?.plate_no}
              <br></br>
              <span>Address:</span> {data?.address}
              <br></br>
              <span>City of Operation:</span> {data?.city}
              <br></br>
              <span>State of Operation:</span> {data?.state}
              <br></br>
            </div>
            <div className="agent-about">
              <div className="agent-about-name">Bank Details</div>
              <br></br>
              <span>Bank Name :</span>{" "}
              {DataChecker(data?.bank_details?.bank_name)}
              <br></br>
              <span>Account Name:</span>{" "}
              {DataChecker(data?.bank_details?.account_name)}
              <br></br>
              <span>Account Type:</span>{" "}
              {DataChecker(data?.bank_details?.account_type)}
              {/* <br></br>
              <span>BVN:</span> {DataChecker(data?.bank_details?.bvn)} */}
              <br></br>
              <span>Account Number: </span>{" "}
              {DataChecker(data?.bank_details?.account_no)}
              <br></br>
              {/* <div className="delivery-vehicle-type-medium2">
                <div className="delivery-vehicle-type-medium-name1">
                  Drivers License
                </div>
                <div className="delivery-vehicle-type-medium-name-vehicle1">
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[0]
                        ? data?.vehicle_details?.img_urls?.[0]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <img
                    src={
                      data?.vehicle_details?.img_urls?.[1]
                        ? data?.vehicle_details?.img_urls?.[1]
                        : vehicleimgavatar
                    }
                    className="delivery-vehicle-type-medium-name-vehicle2"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div> */}
            </div>

            <div className="earning-board">
              <div className="agent-stats-board">
                <div className="agent-flboard">
                  <img src={totalearning} className="deliv-icons" alt="" />{" "}
                  Total earning's
                </div>{" "}
                <br></br>
                {ZeroChecker(data?.total_earnings)}
              </div>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <label className="agent-form-label">
              Vehicle & License Information
            </label>
            <div>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={(e) => addNewVehicleImage(e)}
              />

              {/* Custom button to trigger file selection */}
              <button
                className="add-more-images-button"
                onClick={handleButtonClick}
              >
                +
              </button>
            </div>
          </div>
          <div className="delivery-vehicle-type-medium2">
            <div className="vehicle-information">
              {vehicleImgs.map((url, index) => (
                <div className="vehicle-information-unit">
                  <img
                    key={index}
                    src={url ? url : vehicleimgavatar}
                    alt="icon"
                    className="vehicle-image"
                    // style={{objectFit: "cover"}}
                  />
                  <div className="vh-buttons-container">
                    <button
                      onClick={() => removeImage(index)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                      }}
                    >
                      X
                    </button>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      onChange={(e) => chooseAnotherVehicleImage(e, index)}
                      className="hide-no-file-chosen"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div id="vehicle-images-parent">
            <div>
              <label className="agent-form-label">
                Driver's licence expiry date
              </label>

              {/* Display the selected date */}
              <div className="form-information">
                {driver_license_expiry_date
                  ? driver_license_expiry_date
                  : "Select drivers license expiry date"}
              </div>

              {/* Date picker */}
              <input
                type="date"
                value={driver_license_expiry_date}
                onChange={handleDateChange}
                className="date-picker-input"
              />
            </div>

            <button
              className="vh-update-button"
              disabled={disableUpdateVehicleInfo}
              onClick={() => handleUpdatingVehicleImages()}
            >
              {disableUpdateVehicleInfo ? (
                <ClipLoader
                  color={"black"}
                  loading={disableUpdateVehicleInfo}
                  size={15}
                />
              ) : (
                "Update"
              )}
            </button>
          </div>
          <br></br>
          <br></br>

          <div className="agent-history">
            <div className="agent-instant-request">
              <div className="agent-flash-display">
                <img src={insdelivery} className="deliv-icons" alt="" />
                <span className="flash-display">No of Instant Deliveries</span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_instant_deliveries}
              </div>
            </div>
            <div className="agent-scheduled-request">
              <div className="agent-flash-display">
                <img src={calender} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Scheduled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_of_scheduled_deliveries}
              </div>
            </div>
            <div className="agent-cancelled-request">
              <div className="agent-flash-display">
                <img src={cancelled} className="deliv-icons" alt="" />
                <span className="flash-display">
                  No of Cancelled Deliveries
                </span>
              </div>
              <div className="agent-flash-display-no">
                {data?.no_cancelled_deliveries}
              </div>
            </div>
          </div>
          <div className="agent-ratings">
            <div className="found-not">
              <img src={emptybox} alt="not found" />
              <p>No ratings and reviews for this delivery agent</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
