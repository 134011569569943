import React from 'react'
import {Link} from 'react-router-dom';
import emptybox from '../images/emptybox.png';
import '../css/no_agents_application.css';
import {FaSearch} from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';

export default function No_Fleet() {

    const navigate = useNavigate();

  return (
    <div className='no-users'>
        <div className='no-users-properties'>
              <div className='back1'>
                  <FontAwesomeIcon icon={faArrowLeftLong} onClick={() => navigate(-1)} className='back'></FontAwesomeIcon>       
              </div>
            <h1 className='no-users-title'>Fleet Managers</h1>
            <div className='no-users-header'>
                <div className='no-users-search-box-container'>
                    <input type="text" placeholder='Search Users' className='no-users-search-box'/><button className='no-searching'><FaSearch /></button>
                    <Link to='/App/Blocked_Fleet'> <button className='no-users-block'>View blocked List</button></Link>
                </div>                
            </div>
            <div className='align-not-found'>
                <div className='none11-found'>
                    <img src={emptybox} alt='No agents Found' className='no1-found'  />                    
                </div>
                <div className='no-title11'>No Fleet Managers found at the moment</div>
            </div>
        </div>
    </div>
  )
}
