import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import dropdown from "../../component/images/dropdown.png";
import { useNavigate } from "react-router-dom";
import NoUserReward from "./NoUserReward";

const UserReferral = ({ choosenReward }) => {
  const navigate = useNavigate();
  const token = JSON.parse(sessionStorage.getItem("userToken"));
  const [isLoaded, setIsLoaded] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [result, setResult] = useState();
  const [pageCount, setPageCount] = React.useState(1);

  useEffect(() => {
    fetchData();
  }, [isLoaded]);

  const fetchData = async () => {
    try {
      // setIsLoaded(false);
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_referral/view_qualified_users",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);

      setResult(resultM);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const [modalData, setModalData] = useState({
    isOpen: false,
    message: "",
    user_referral_id: null,
  });
  const [selectedReward, setSelectedReward] = useState(null);

  const handleRewardStatusClick = (user) => {
    const user_referral_id = user._id;

    const message = `Are you sure you want to reward ${user.fullname} with "${choosenReward}" Reward?`;
    setButtonLoading(false);
    setModalData({ isOpen: true, message, user_referral_id });
    closeDropdown();
  };

  const closeModal = () => {
    // console.log(`----------> ${modalData.user_referral_id}`);
    setModalData({ isOpen: false, message: "", user_referral_id: null });
  };

  const [dropdownStates, setDropdownStates] = useState({});

  const toggleDropdown = (index) => {
    closeDropdown();
    setDropdownStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle visibility for the specific row
    }));
  };

  const closeDropdown = () => {
    setDropdownStates({}); // Close all dropdowns
  };

  const handleIndvidualReferral = () => {
    // Navigate to the News page
    navigate("/App/individual_user_referrals");
  };

  const confirmAction = async () => {
    setButtonLoading(true);

    try {
      const response = await fetch(
        "https://serverpickload.wl.r.appspot.com/admin_referral/reward_user",
        {
          method: "POST",

          body: JSON.stringify({
            token: token,
            referral_id: modalData.user_referral_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/plain, */*",
          },
        }
      );
      // setData(await response.json());
      const resultM = await response.json();
      console.log(resultM);
      setButtonLoading(false);

      if (resultM.msg === "Success") {
        closeModal();
        setIsLoaded(false);
      }
    } catch (error) {
      setButtonLoading(false);
      console.log(error);
    }
  };

  if (!isLoaded) {
    return (
      <div style={{ textAlign: "center" }}>
        <ClipLoader color={"#1FAA08"} size={100} />
      </div>
    );
  } else if (result.msg === "Success") {
    return (
      <div className="referal-table">
        <div className="agent-ref-pop">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone Number</th>
                <th>Reward notifier</th>
                <th>User Reward details</th>
              </tr>
            </thead>
            <tbody>
              {result.referrals.map((item, index) => (
                <tr key={index}>
                  <td>{item.fullname}</td>
                  <td>{item.phone}</td>
                  <td>{choosenReward}</td>
                  {/* <td>{item.reward_notifier}</td> */}
                  <td>
                    <div className="reward-details">
                      {item.reward_notifier === "cash" ? (
                        <>
                          <div>Account Name: {item.ref_account_name}</div>
                          <div>Account Number: {item.ref_account_no}</div>
                          <div>Bank name: {item.ref_bank}</div>
                        </>
                      ) : (
                        <>
                          <div>Network Provider: {item.service_provider}</div>
                          <div>Phone Number: {item.phone_recharge}</div>
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <img
                      src={dropdown}
                      alt="icon"
                      className="reward-ref-img"
                      onClick={() => toggleDropdown(index)}
                    />
                    {dropdownStates[index] && (
                      <ul className="reward-dropdown-menu">
                        <li onClick={handleIndvidualReferral}>
                          View Referrals
                        </li>
                        <li onClick={() => handleRewardStatusClick(item)}>
                          Reward User
                        </li>
                      </ul>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {modalData.isOpen && (
            <div className="reward-overlay">
              <div className="reward-modal">
                <h1>User Reward</h1>
                <h2>{modalData.message}</h2>
                <button className="modal-btn" onClick={closeModal}>
                  No
                </button>
                <button className="modal-btn1" onClick={confirmAction}>
                  {buttonLoading ? "..." : "Yes"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else if (result.msg === "no rewarded users found") {
    return <NoUserReward />;
  } else if (
    result.msg === "Account has been blocked, please contact master admin"
  ) {
    return (
      <p style={{ textAlign: "center" }}>
        Account has been blocked, please contact master admin
      </p>
    );
  }
};

export default UserReferral;
