import React, { useState, useEffect } from "react";
import "../../component/css/support.css";
import { useNavigate } from "react-router-dom";
import User_Support from "./User_Support";
import Agent_Support from "./Agent_Support";
import Guest_Support from "./Guest_Support";

export default function Support() {
  const [toggle, setToggle] = useState("first");
  const [data, getData] = useState([]);
  // const [isLoaded, setIsLoaded] = useState(false);
  // const [result, setResult] = useState();
  const navigate = useNavigate();

  const firstClick = () => {
    setToggle("first");

    // navigate("/Pending-del");
  };

  const secondClick = () => {
    setToggle("second");
    // navigate("/Pending-del");
  };

  const thirdClick = () => {
    setToggle("third");
  };
  // setResult(result)
  // setIsLoaded(true)

  // if(!isLoaded) {
  //   //console.log('got here')
  //   return <h1 className='loading-pages'>Loading.....</h1>
  // }else if(result.msg === 'Success') {
  //   //console.log('data gotten succesfully');

  return (
    <div className="support">
      <div className="support-to">
        <div
          className="payment-toggle"
          onClick={firstClick}
          id={toggle == "first" ? "active-support" : "inactive-support2"}
        >
          Users
        </div>
        <div
          className="payment1-toggle"
          onClick={secondClick}
          id={toggle == "second" ? "active-support2" : "inactive-support"}
        >
          Delivery Agents
        </div>
        <div
          className="payment1-toggle"
          onClick={thirdClick}
          id={toggle == "third" ? "active-support2" : "inactive-support"}
        >
          Guest
        </div>
      </div>

      {toggle === "first" ? (
        <User_Support
          click={() => {
            navigate("/App/User_Support");
          }}
        />
      ) : toggle === "second" ? (
        <Agent_Support
        // click2={() => {
        //   navigate("/App/Agent_Support");
        // }}
        />
      ) : toggle === "third" ? (
        <Guest_Support />
      ) : null}
    </div>
  );
  // }
}
